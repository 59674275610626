/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to distribute, remix, adapt without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../aauMixinLoader/aauMixinLoader';

import axios from 'axios';
import Cookies from 'js-cookie';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {AauPageDaahboardCard} from "./aauPageDashboardCard";
import {casdoor} from '../../casdoor';


export interface AauPageDashboardProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageDashboardProps;

export const AauPageDashboard: FC<AauPageDashboardProps> = props => {
    const [state, setState] = useState<string>(null);
    const [data, setData] = useState(null);

    let content = <AauMixinLoader />;

    const retrieveData = async () => {
        await axios.get(
            `/rest/dashboard`,
            {
                baseURL: `${window.generator.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        content = <ResponsiveMasonry
            className='p-5 mt-20 pt-10 grid'
            columnsCountBreakPoints={{600: 2, 1200: 3, 1800: 3, 2400:4, 3000:5}}
        >
            <Masonry gutter={20}>
            {
                data['widgets'].map((widget, index) => {
                    return <AauPageDaahboardCard
                        id={`widget_${widget['name']}`}
                        key={`widget_${widget['name']}`}
                        title={widget['label']}
                        description={widget['description']}
                        width={widget['width']}
                        height={widget['height']}
                        icon={widget['icon']}
                        //
                        data={widget['data']}
                    />
                })
            }
            </Masonry>
        </ResponsiveMasonry>;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //SONAR
    }

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['title'] : ''}
                pageHelp={null}
                menuBadgeText={'v1.0'}
                menuBadgeState={'new'}
            />
            {content}
        </div>
    );
};
AauPageDashboard.defaultProps = defaultProps;
