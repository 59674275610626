/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import * as FluentUiIcon from "@fluentui/react-icons"
import {tokens} from "@fluentui/react-components";

export interface AauMixinIconProps {
    icon: string;
    style?: 'standard' | 'fab';
    color?: string;
}

const defaultProps = {
    style: 'standard',
    color: tokens.colorNeutralForeground4
} as AauMixinIconProps;

export const AauMixinIcon: FC<AauMixinIconProps> = props => {
    return [null, ''].includes(props.icon)
        ? <div/>
        : React.createElement(
            FluentUiIcon[props.icon],
            {
                style: props.style === 'fab' ? {} : {
                    fontSize: 10,
                    color: props.color
                }
            },
            null
        );
};
AauMixinIcon.defaultProps = defaultProps;
