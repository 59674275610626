/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import JsonView from '@uiw/react-json-view';
import { lightTheme } from '@uiw/react-json-view/light';
import { darkTheme } from '@uiw/react-json-view/dark';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/fr';
import Cookies from "js-cookie";

export interface AauMixinJsonProps {
    value: any;
    height?: number;
    treemode?: boolean;
}

const defaultProps = {
    height: 200,
    treemode: false
} as AauMixinJsonProps;

export const AauMixinJson: FC<AauMixinJsonProps> = props => {
    const isLogged = typeof (Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
    let profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

    return <div className='p-2'>
        {
            props.treemode === false ? <JSONInput
                placeholder={props.value}
                locale={locale}
                height={`${props.height}px`}
                width='100%'
                viewOnly={true}
            /> : <JsonView value={props.value} style={(profil.hasOwnProperty('darkMode') ? profil['darkMode'] : true) === true ? darkTheme : lightTheme} />
        }
    </div>;
};
AauMixinJson.defaultProps = defaultProps;
