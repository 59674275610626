/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauMixinIcon} from '../aauMixinIcon/aauMixinIcon';

export type AauMixinStrLinkScheme = 'http' | 'https';


export interface AauMixinStrLinkProps {
    url: string;
    scheme?: AauMixinStrLinkScheme;
    text?: string;
}

const defaultProps = {
    url: '#',
    scheme: 'https',
    text: '',
} as AauMixinStrLinkProps;

export const AauMixinStrLink: FC<AauMixinStrLinkProps> = props => {
    return <a href={`${props.scheme}://${props.url}`} target='_blank'  rel='noopener noreferrer' style={{color: "inherit", textDecoration: 'none'}} className='p-2'>
        <span style={{
            marginRight: '5px'
        }}>{props.text}</span>
        <AauMixinIcon icon={`Open16Filled`}/>
    </a>;
};
AauMixinStrLink.defaultProps = defaultProps;
