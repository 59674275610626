/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {Input, Slider} from "@fluentui/react-components";
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormSliderProps {
    label?: string;
    readonly: boolean;
    itemKey: string;
    key: string;
    name: string;
    value: number;
    validator: object;
    parentFunction?: any;
    step?: number;
    min?: number;
    max?: number;
    required?: boolean | null;
    hint?: string;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {
        'required': true
    },
    parentFunction: null,
    value: 0,
    step: 1,
    min: 1,
    max: 1,
    hint: null
} as AauFormSliderProps;

export const AauFormSlider: FC<AauFormSliderProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (isNaN(validateValue) || (props.required === true && (validateValue === '' || validateValue === null))) {
            isValid = false;
        }

        if (validateValue < props.min) {
            validateValue = props.min
        } else if (validateValue > props.max) {
            validateValue = props.max
        } else if ( validateValue % props.step !== 0 ) {
            validateValue = Math.round(validateValue / props.step) * props.step;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': props.value
    });
    if (state['isValid'] === null && props.readonly === false ) {
        validateField(props.value);
    }

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        hint={props.hint}
        content={
            <div className={`width-100`}>
                <Slider
                    key={`${props.itemKey}-ctrl-slide`}
                    aria-labelledby={props.name}
                    size={`medium`}
                    value={state['content']}
                    min={props.min}
                    max={props.max}
                    // step={props.step}
                    onChange={props.readonly ? null : (e, data) => validateField(Number(data['value']))}
                    className={`float-left`}
                    readOnly={props.readonly}
                    style={{
                        width: `calc(100% - 100px)`
                    }}
                />
                <Input
                    style={{
                        borderWidth: props.readonly ? 0 : 1
                    }}
                    key={`${props.itemKey}-ctrl-text`}
                    name={props.name}
                    aria-labelledby={props.name}
                    appearance={`outline`}
                    value={state['content']}
                    onChange={props.readonly ? null : (e, data) => {
                        validateField(Number(data['value']))
                    }}
                    onMouseOut={props.readonly ? null : (e) => {
                        let tmpValue = Math.round(parseInt(e.target['value']) / props.step) * props.step
                        validateField(tmpValue);
                    }}
                    onBlur={props.readonly ? null : (e) => {
                        let tmpValue = Math.round(parseInt(e.target['value']) / props.step) * props.step
                        validateField(tmpValue);
                    }}
                    // onChange={props.readonly ? null : (e, data) => setState({
                    //     'isValid': state['isValid'],
                    //     'content': isNaN(parseInt(e.target['value'])) ? props.min : parseInt(e.target['value'])
                    // })}
                    type={`number`}
                    size={`medium`}
                    className={`float-right width-px-90 text-center`}
                    step={props.step}
                    readOnly={props.readonly}
                />
            </div>
        }
    />;
};
AauFormSlider.defaultProps = defaultProps;