/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauFormText} from '../aauFormText/aauFormText';
import {AauFormInput} from '../aauFormInput/aauFormInput';
import {AauFormSelect} from '../aauFormSelect/aauFormSelect';
import {AauFormSlider} from '../aauFormSlider/aauFormSlider';
import {AauFormDateTime} from '../aauFormDateTime/aauFormDateTime';
import {AauFormEditorCode} from '../aauFormEditorCode/aauFormEditorCode';
import {AauFormUpload} from '../aauFormUpload/aauFormUpload';
import {AauDynamicAccordion} from '../aauDynamicAccordion/aauDynamicAccordion';
import {AauFormBoolean} from '../aauFormBoolean/aauFormBoolean';
import {AauFormEditorWysiwig} from '../aauFormEditorWysiwig/aauFormEditorWysiwig';
import {AauFormJson} from "../aauFormJson/aauFormJson";
import {
    Tooltip,
    Toolbar,
    ToolbarButton,
    DialogTrigger,
    DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Dialog, Card, CardHeader, CardPreview, Subtitle1
} from "@fluentui/react-components";
import {SendCopy24Regular, Save24Regular} from '@fluentui/react-icons';

import {Dismiss24Regular} from '@fluentui/react-icons';
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";
import {tokens} from "@fluentui/react-theme";
import nextId from "react-id-generator";
import {AauFormPersona} from '../aauFormPersona/aauFormPersona';


export interface AauComponentFormProps {
    columns: object;
    item?: object;
    mainForm?: boolean;
    readonly?: boolean;
    dynamicKey?: string;
    parentFunction?: any; // NOSONAR
    saveHandler?: (object)=>void;
    saveActionHandler?: (object)=>void | null;
    saveAndActionLabel?: string;
    dialogMode?: boolean;
    refHandler?: any;
    className?: string;
    templateVersion?: number,
    template?: object;
    title?: string;
    lang: AauToolsLang;
    dialogOpen?: boolean;
    setDialogOpen?: (boolean)=>void;
}

const defaultProps = {
    item: {},
    dialogMode: false,
    mainForm: true,
    readonly: false,
    dynamicKey: null,
    saveHandler: null,
    saveActionHandler: null,
    saveAndActionLabel: 'Save and Action',
    templateVersion: 1,
    template: null,
    className: '',
    title: null
} as AauComponentFormProps;

export const AauComponentForm: FC<AauComponentFormProps> = props => {
    const { columns, item, mainForm, readonly, dynamicKey, parentFunction, saveHandler, saveActionHandler,
            saveAndActionLabel, dialogMode, lang, className, template} = props;

    // const [dialogOpen, setDialogOpen] = useState(true);

    const [state, setState] = useState({
        updated: Date.now(),
        content: dialogMode === true ? {} : item,
        valid: {}
    });
    const [valid, setValid] = useState(false);
    const formSetData = (keyName: string, value: any, valid: boolean): void => {
        const subKeyNameIndex = 2;
        const key = mainForm ? keyName : keyName.split('_')[subKeyNameIndex];

        state['updated'] = Date.now();
        state['content'][key] = value;
        state['valid'][key] = valid;

        const index = keyName.split('_')[1];        
        if (parentFunction !== null && typeof (parentFunction) !== 'undefined' && mainForm === false && typeof (index) !== 'undefined') {
            parentFunction(parseInt(index, 10), key, value, !Object.values(state['valid']).includes(false));
        }

        setState(state);
        setValid(Object.values(state['valid']).filter(x => x === false).length === 0);
    };

    let formControlBtn = null;
    if (mainForm && !dialogMode) {
        formControlBtn = <Toolbar
            key={`toolbar_fab_save`}
            size={'small'}
            aria-label="Record Action Toolbar"
            vertical={true}
            style={{
                position: 'fixed',
                right: '10px',
                top: '150px',
                zIndex: 1000,
                backgroundColor: tokens.colorNeutralShadowKeyDarker
            }}
        >
            {
                saveHandler !== null &&  <Tooltip key={`fab_save`} content={lang.getBtn('save')} relationship="description">
                    <ToolbarButton
                        as={'button'}
                        appearance={`primary`}
                        icon={<Save24Regular />}
                        disabled={!valid}
                        style={{border: '1px', borderColor: tokens.colorNeutralStroke2, borderStyle: 'solid'}}
                        onClick={() => {
                            if ( Object.values(state['valid']).filter(x => x === false).length === 0 ) {
                                saveHandler(state['content'])
                            }
                        }}
                    />
                </Tooltip>
            }
            {
                saveActionHandler !== null &&  <Tooltip key={`fab_save_and_action`} content={saveAndActionLabel} relationship="description">
                    <ToolbarButton
                        as={'button'}
                        icon={<SendCopy24Regular />}
                        disabled={!valid}
                        style={{border: '1px', borderColor: tokens.colorNeutralStroke2, borderStyle: 'solid'}}
                        onClick={() => {
                            if ( Object.values(state['valid']).filter(x => x === false).length === 0 ) {
                                saveActionHandler(state['content'])
                            }
                        }}
                    />
                </Tooltip>
            }
        </Toolbar>;
    } else {
        //NOSONAR
    }

    const getComponent = c => {
        const co: object = c.hasOwnProperty('component') ? c['component'] : {};
        const key: string = c['name'];
        const itemKey = (dynamicKey !== null ? `${dynamicKey}_${key}` : key);
        let mixin = co.hasOwnProperty('role') && co['role'] !== '' ? co['role'] : c['field_type'];

        let value = state['content'].hasOwnProperty(key) ? state['content'][key] : (state.hasOwnProperty(key) ? state[key] : ( dialogMode === true ? item[key] : c['default']));
        if (Object.keys(state['content']).length === 0 && dialogMode === false) {
            value = c['default'];
        }

        const validator = c.hasOwnProperty('validator') ?  c['validator'] : {} ;
        const editState = c.hasOwnProperty('edit_state') && c['edit_state'] !== null ? c['edit_state'] : false;
        const editRank = c.hasOwnProperty('edit_rank') ? c['edit_rank'] : false;
        const viewState = c.hasOwnProperty('view_state') && c['view_state'] !== null ? c['view_state'] : false;
        const viewRank = c.hasOwnProperty('view_rank') ? c['view_rank'] : false;

        let readOnlyTmp = true;
        if ( mainForm === false ) {
            readOnlyTmp = c['readonly'] === true ? true : readonly;
        }
        if ( saveHandler !== null ) {
            readOnlyTmp = editState !== false ? false : (c['readonly'] === true ? true : readonly);
        }
        console.log(state['content']);
        if ( ["name", "envinfra"].includes(key) && (state['content'].hasOwnProperty(key) && state['content']['deployState'] === true) ){
            readOnlyTmp = true;
        }

        if (mainForm === true
            && (
                editRank === false
                || (
                    editState !== false
                    && (
                        (Object.keys(state['content']).length > 0 && editState.indexOf(state['content']['state']) === -1)
                        || (Object.keys(state['content']).length === 0 && editState.indexOf(0) === -1)
                    )
                )
            )
        ) {
            readOnlyTmp = true;
        }

        if (mainForm === true
            && (
                readOnlyTmp
                && (viewRank === false
                    || ['btnactiongroup', 'isDeleted'].includes(itemKey)
                    || (
                        viewState !== false
                        && (
                            (Object.keys(state['content']).length > 0 && viewState.indexOf(state['content']['state']) === -1)
                            || (Object.keys(state['content']).length === 0 && viewState.indexOf(0) === -1)
                        )
                    )
                )
            )
        ) {
            readOnlyTmp = true;
            mixin = 'HIDDEN';
        }

        if (mainForm === false && c.hasOwnProperty('depends') && c['depends'] !== null && c['depends'] !== '' && (
            Object.keys(state['content']).length == 0 
            || (Object.keys(state['content']).length > 0 && !state['content'].hasOwnProperty(c['depends']['field'])) 
            || (Object.keys(state['content']).length > 0 && state['content'].hasOwnProperty(c['depends']['field']) && !c['depends']['value'].includes(state['content'][c['depends']['field']])))) {
                mixin = 'HIDDEN';
        }        

        console.debug("Main Form: " + mainForm + " - Save Handler: " + (saveHandler !== null) + " - Key: " + key + " - value: " + value + " - isnull: " + (value === null) +  " - Mixin: " + mixin + " - ReadOnly: " + readonly + " - ReadOnlyTmp: " + readOnlyTmp + " - editRank: " + editRank + " - editState: " + editState + " - viewRank: " + viewRank + " - viewState: " + viewState);

        let content;
        switch (mixin) {
            case 'VARCHAR':
                content = <AauFormInput
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    prefix={<AauMixinIcon icon={c['prefix']} />}
                    suffix={<AauMixinIcon icon={c['suffix']} />}
                    isInteger={false}
                    parentFunction={formSetData}
                    {...validator}
                />;
                break;
            case 'TEXT':
                content = <AauFormText
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    rows={co.hasOwnProperty('rows') ? co['rows'] : 10}
                    parentFunction={formSetData}
                    {...validator}
                />;
                break;
            case 'ENUM':
                content = <AauFormSelect
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value !== null ? value.toString() : ''}
                    hint={co['helper']}
                    multiple={c['multiple']}
                    dualmode={co.hasOwnProperty('dualmode') ? co['dualmode'] : false}
                    parentFunction={formSetData}
                    enums={co.hasOwnProperty('enums') ? co['enums'] : {}}
                    {...validator}
                />;
                break;                    
            case 'persona':
                content = <AauFormPersona
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value !== null ? value.toString() : ''}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    enums={co.hasOwnProperty('enums') ? co['enums'] : {}}
                    {...validator}
                />;
                break;
            case 'text_code':
                content = <AauFormEditorCode
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'text_wysiwyg':
                content = <AauFormEditorWysiwig
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'slider':
                content = <AauFormSlider
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    min={co['min']}
                    max={co['max']}
                    step={co['step']}
                    marks={co['marks']}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'INTEGER':
            case 'BIGINT':
            case 'NUMERIC':
            case 'FLOAT':
            case 'INT':
            case 'DOUBLE_PRECISION':
            case 'DOUBLE PRECISION':
                content = <AauFormInput
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    prefix={c['prefix']}
                    suffix={c['suffix']}
                    min={co['min']}
                    max={co['max']}
                    isInteger={true}
                    type='number'
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'TIMESTAMP WITH TIME ZONE':
            case 'DATETIME':
            case 'DATE':
            case 'TIME':
            case 'TIMESTAMP':
                content = <AauFormDateTime
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'BOOLEAN':
            case 'BOOLEANINT':
                content = <AauFormBoolean
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'UPLOAD':
            case 'BYTES':
                content = <AauFormUpload
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'JSON':
            case 'JSONB':
                content = <AauFormJson
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    hint={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'dynamicForm':
                content = <AauDynamicAccordion
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    columns={co['subForm']}
                    titleStr={co.hasOwnProperty('substate_title') ? co['substate_title'] : `{${co['subForm'][0]['name']}}`}
                    name={key}
                    template={co.hasOwnProperty('template_form') ? co['template_form'] : null}
                    templateVersion={co.hasOwnProperty('template_version') ? co['template_version'] : null}
                    label={c['label']}
                    value={(value !== null && co.hasOwnProperty('sub_table')) ? value : ((value !== null && value.hasOwnProperty(key)) ? value[key] : [])}
                    hint={co['helper']}
                    substate={c['subState']}
                    parentFunction={formSetData}
                    dynamicKey={dynamicKey}
                    mainForm={false}
                    {...validator}
                    lang={lang}
                />;
                break;
            case 'HIDDEN':
                content = null;
                break;
            default:
                content = <div className={`mb-5 text-bold`}>
                    {itemKey} with {mixin} is not implemented
                </div>;
        }

        return content;
    };

    const buildForm = () => {
        const colInfo = {};

        Object.keys(columns).forEach(cid => {
            const c: object = columns[cid];
            let content = getComponent(c);
            if ( content !== null ) {
                colInfo[c['name'].toLowerCase()] = content;
            }
        });

        return props.templateVersion === 2 ? buildFromV2(colInfo) : buildFromV1(colInfo);
    };

    const buildFromV1 = (colInfo) => {
        let rowContent;
        let component = 0;
        let zpos = 200;

        return <div className='ms-Grid'>
            {
                Object.keys(template).map(rid => {
                    return <div
                        key={`generator-form-div-${rid}`}
                        className='ms-Grid-row'
                        style={{zIndex: zpos - parseInt(rid)}}
                    >
                        {
                            Object.entries(template[rid]).map(([key, value]) => {
                                const cname = Object.keys(value)[0];
                                const csize = value[cname];

                                if ( colInfo.hasOwnProperty(cname.toLowerCase()) ) {
                                    rowContent = true;
                                    component += 1;
                                }

                                let content = null;
                                if ( cname !== '-' ) {
                                    content = <div 
                                        key={`generator-form-component-${cname}`}
                                        className={`ms-Grid-col ms-sm${csize} pr-3 pl-3 float-left`} style={{zIndex: zpos - parseInt(rid)}}
                                    >
                                        {colInfo[cname.toLowerCase()]}
                                    </div>;
                                } else {
                                    if ( rowContent === true && component < Object.keys(colInfo).length ) {
                                        content = <div className={`ms-Grid-col ms-sm12 p-8`}>
                                            <hr key={`generator-form-sep-${nextId()}`} className={`width-80 bd-1`} />
                                        </div>;
                                    }
                                    rowContent = false;
                                }

                                return content;
                            })
                        }
                    </div>;
                })
            }
        </div>;        
    }

    const buildFromV2 = (colInfo) => {
        let rowContent;
        let component = 0;
        let zpos = 200;

        return <div className='ms-Grid'>
            {
                Object.keys(template).map(cadreid => {
                    return <div 
                        key={`generator-form-div-${cadreid}`}
                        className={`ms-Grid-col ms-sm${template[cadreid]['size']}`}
                        style={{
                            clear: template[cadreid]['newline'] === true ? 'both' : 'none',
                            zIndex: zpos - parseInt(cadreid)
                        }}
                    >
                        <Card
                            key={`card-${cadreid}`}
                            size={`medium`}
                            style={{
                                padding: '0px',
                                marginBottom: '20px',
                                float: 'left',
                                width: '100%',
                                boxShadow: '3px 3px 10px 0px rgba(0, 0, 0, .6)'
                            }}
                        >
                            <CardHeader
                                key={`card-${cadreid}-header`}
                                style={{
                                    lineHeight: '3rem',
                                    backgroundColor: tokens.colorPalettePlatinumBackground2,
                                    color: tokens.colorNeutralForeground4,
                                    borderBottom: '1px',
                                    textAlign: 'center',
                                    padding: 10,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, .6)'
                                }}
                                description={
                                    <Subtitle1>
                                        {template[cadreid]['name']}
                                    </Subtitle1>
                                }
                            />

                            <CardPreview
                                key={`card-${cadreid}-preview`}
                                className={`ms-Grid-Grid`}
                                style={{
                                    padding: '5px'
                                }}
                            >
                                {
                                    Object.keys(template[cadreid]['components']).map(crid => {
                                        return <div key={`row-${crid}`} className='ms-Grid-row'>
                                            {
                                                Object(template[cadreid]['components'][crid]).map(cname => {
                                                    return cname !== "" ? <div
                                                        key={`row-${crid}-${cname}`}
                                                        className={`ms-Grid-col ms-sm${Math.trunc(12/template[cadreid]['components'][crid].length).toString()}`}
                                                        style={{
                                                            padding: '20px',
                                                            paddingBottom: '5px',
                                                            paddingTop: '5px'
                                                        }}
                                                    >
                                                        {cname !== "-" ? colInfo[cname.toLowerCase()] : <hr key={`row-${crid}-sep-${nextId()}`} className={`width-80 bd-1`} />}
                                                    </div> : null;
                                                })
                                            }
                                        </div>; 
                                    })
                                }
                            </CardPreview>
                        </Card>
                    </div>;
                })
            }
        </div>;        
    }


    const buildSubForm = () => {
        let zpos = 300;
        return <div className='ms-Grid'>
            {
                Object.keys(columns).map(cid => {
                    const c: object = columns[cid];

                    const content = getComponent(c);
                    return content !== null && <div className='ms-Grid-row' style={{zIndex: zpos - parseInt(cid)}}>
                        <div className={`ms-Grid-col ms-sm12 p-2`}>
                            {content}
                        </div>
                    </div> || null;
                })
            }
        </div>
    };

    return dialogMode === false ? (
        <div className={``}>
            {
                mainForm ?
                <form  className={className}>{dialogMode || template === null ? buildSubForm() : buildForm()}</form> :
                <div className={`p-2`}>{template === null ? buildSubForm() : buildForm()}</div>
            }
            {saveHandler !== null ? formControlBtn : null}
            <div className='float-clear'></div>
        </div>
    ) : <Dialog open={props.dialogOpen} modalType={`alert`}>
        <DialogSurface>
            <DialogBody>
                <DialogTitle
                    // action={
                    //     <DialogTrigger action="close">
                    //         <Button
                    //             onClick={() => {
                    //                 props.setDialogOpen(false);
                    //             }}
                    //             appearance="subtle"
                    //             aria-label="close"
                    //             icon={<Dismiss24Regular />}
                    //         />
                    //     </DialogTrigger>
                    // }
                >
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    {buildSubForm()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        props.setDialogOpen(false);
                    }}>
                        {lang.getBtn('close')}
                    </Button>
                    <Button onClick={() => {
                        saveHandler(state['content']);
                        props.setDialogOpen(false);
                    }} appearance="primary">
                        {lang.getBtn('save')}
                    </Button>
                    <Button onClick={() => {
                        saveActionHandler(state['content']);
                        props.setDialogOpen(false);
                    }} appearance="primary">
                        {saveAndActionLabel}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>;
};
AauComponentForm.defaultProps = defaultProps;
