/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

export type MoneyType = 'euro' | 'dollar' | 'pound' | 'rubble' | 'yen';

// TODO(vincent.candeau): REVAMP COLOR

export interface AauMixinStrCompareProps {
    value1: string | null;
    value2: string | null;
    colorOK?: string;
    colorNULL?: string;
    colorKO?: string;
    percent?: boolean;
}

const defaultProps = {
    value1: '-',
    value2: '-',
    colorOK: 'l5-green',
    colorNULL: 'l5-orange',
    colorKO: 'l5-red',
    percent: false
} as AauMixinStrCompareProps;

export const AauMixinStrCompare: FC<AauMixinStrCompareProps> = props => {
    const { value1, value2, colorOK, colorNULL, colorKO, percent } = props;

    const cssGeneral = 'bd-radius-4 font-12 text-center text-bold fg-white p-1 width-75 mx-auto';

    let content;
    if ( ['-', null].indexOf(value1) !== -1 || ['-', null].indexOf(value2) !== -1 ) {
        content = (
            <div className={`bg-${colorNULL} ${cssGeneral}`}>-</div>
        );
    } else if ( value1 === value2 ) {
        content = (
            <div className={`bg-${colorOK} ${cssGeneral}`}>{value1} { percent === true ? ' (100%)' : '' }</div>
        );
    } else {
        const numeric100 = 100;
        const percentValue = typeof(value1) === 'number' && typeof(value2) === 'number' ? Math.round(value2 * numeric100 / value1) : '';
        content = (
            <div className={`bg-${colorKO} ${cssGeneral}`}>
                {value1} / {value2} {percent ? `(${percentValue}%)` : '' }
            </div>
        );
    }

    return content;
};
AauMixinStrCompare.defaultProps = defaultProps;
