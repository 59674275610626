/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';
import {PacmanLoader} from 'react-spinners';


export const AauMixinLoader: FC = props => {
    return <div className='d-block width-px-40 height-px-40 center-screen'>
        <PacmanLoader
            color={`#FFDD00`}
            loading={true}
            size={30}
        />
    </div>;
};
AauMixinLoader.defaultProps = {};
