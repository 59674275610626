/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';


export interface AauMixinStrYamlProps {
    value: string;
}

const defaultProps = {
} as AauMixinStrYamlProps;

export const AauMixinStrYaml: FC<AauMixinStrYamlProps> = props => {
    const {value} = props;

    return <pre className={`p-2 bd-black bd-1 bd-top-none bd-bottom-none bd-right-none`}>
        {value}
    </pre>;
};
AauMixinStrYaml.defaultProps = defaultProps;
