
/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauMixinPageHeader} from "../aauMixinPageHeader/aauMixinPageHeader";


export interface AauPageForbiddenProps {
    msg: string;
}

const defaultProps = {
} as AauPageForbiddenProps;

export const AauPageForbidden: FC<AauPageForbiddenProps> = props => {
    return <div className='width-vw-99 height-95-min'>
        <AauMixinPageHeader
            pageTitle={"Forbidden"}
            pageHelp={"Forbidden"}
        />
        <div className='p-5 pl-7 pos-absolute width-95 height-vh-90 top-px-87'>
            <div className={`width-px-600 mx-auto text-center`}>
                <img alt='Forbidden Logo' className={`mt-10 width-px-600 height-px-600`} src={`${window.generator.ASSETSURL}${window.generator.FORBIDDENURL}`}/>
                <br/>
                {props.msg}
            </div>
        </div>
    </div>;
};
AauPageForbidden.defaultProps = defaultProps;
