/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr'


export interface AauMixinTimeAgoProps {
    value: number | string;
    type?: 'ts' | 'dt';
}

const defaultProps = {
    type: 'dt'
} as AauMixinTimeAgoProps;

export const AauMixinTimeAgo: FC<AauMixinTimeAgoProps> = props => {
    TimeAgo.addLocale(fr)

    const timeAgo = new TimeAgo('fr-FR');

    return <div className='text-center'>
        {props.value !== null ? (props.type === 'dt' ? timeAgo.format(Date.parse(props.value.toString())) : 'WIP') : null}
    </div>;
};
AauMixinTimeAgo.defaultProps = defaultProps;
