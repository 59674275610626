/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import dayjs from 'dayjs';


export interface AauMixinTimestampProps {
    value: number | string | object;
    mode: 'display' | 'compare';
    less?: number;
    greater?: number;
    date_format?: string;
}

const defaultProps = {
    mode: 'display',
    less: 40,
    greater: 70,
    date_format: 'DD/MM/YYYY H:m:s'
} as AauMixinTimestampProps;

export const AauMixinTimestamp: FC<AauMixinTimestampProps> = props => {
    const { value, mode, less, greater, date_format } = props;

    let ret = null;
    if ( value !== null ) {
        const dateNow = new Date();
        const dateObj = new Date(typeof(value) === 'number' ?  Number(value) * 1000 : value as string )

        const dateString = dayjs(dateObj).format(date_format);
        const dateMs = dayjs(dateNow).unix() - dayjs(dateObj).unix();

        if (mode === 'compare') {
            let color;

            if (dateMs > greater) {
                color = 'l4-red';
            } else if (dateMs < less) {
                color = 'l3-green-dark';
            } else {
                color = 'l3-orange-dark';
            }

            ret = <div className={`mixin-badge width-px-90-max mx-auto bg-${color}`}>
                {value.toString() === null ? '' : dateString}
            </div>;
        } else {
            ret = <div className='mixin-badge width-px-90-max mx-auto'>
                {value.toString() === null ? '' : dateString}
            </div>;
        }
    } else {
        ret = <div className='mixin-badge width-px-90-max mx-auto'>
        </div>;
    }

    return ret;
};
AauMixinTimestamp.defaultProps = defaultProps;
