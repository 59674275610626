/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinLoader} from '../aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import {AauMixinStrYaml} from "../aauMixinStrYaml/aauMixinStrYaml";
import {Button, Spinner, Toast, ToastBody, ToastTitle, useToastController, Tab, TabList} from "@fluentui/react-components";
import type {SelectTabData, SelectTabEvent, TabValue} from "@fluentui/react-components";
import {tokens} from "@fluentui/react-theme";
import {PaddingLeft24Filled, PaddingRight24Filled} from "@fluentui/react-icons";
import {AauMixinPageHeader} from "../aauMixinPageHeader/aauMixinPageHeader";
import {AauMixinFabBar} from "../aauMixinFabBar/aauMixinFabBar";
import {AauMixinJson} from "../aauMixinJson/aauMixinJson";
import {AauComponentCode} from "../aauComponentCode/aauComponentCode";
import {casdoor} from '../../casdoor';


export interface AauPageFormProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageFormProps;

export const AauPageForm: FC<AauPageFormProps> = props => {
    const {module, action, identifier} = useParams();
    const [data, setData] = useState(null);
    const [dataExtra, setDataExtra] = useState(true);

    const history = useNavigate();
    const {dispatchToast, updateToast} = useToastController('askanduse_toaster');

    const [selectedValue, setSelectedValue] = React.useState<TabValue>("form");
    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    let content = <AauMixinLoader />;

    const submitHandler = async (formData, itemid, actionName, btn) => {
        if ( Object.keys(formData).length > 0 ) {
            dispatchToast(
                <Toast appearance="inverted">
                    <ToastTitle media={<Spinner size="tiny" />}>
                        {btn["progress"]} ({formData["popup_item_desc"]})
                    </ToastTitle>
                </Toast>,
        {
                    toastId: `save_${itemid}}`,
                    timeout: -1
                }
            );
            await axios.post(
                `/rest/${module}/${actionName}`,
                JSON.stringify({
                    identifier: itemid,
                    data: formData
                }),
                {
                    baseURL: `${window.generator.BACKURL}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                    }
                }
            ).then(res => {
                updateToast({
                    content: <Toast appearance="inverted">
                        <ToastTitle>
                            {btn[res.data.success === true ? "success" : "error"]} ({formData["popup_item_desc"]})
                        </ToastTitle>
                        <ToastBody>
                            {res.data.msg}
                        </ToastBody>
                    </Toast>,
                    toastId: `save_${itemid}}`,
                    intent: res.data.success === true ? "success" : "error",
                    timeout: 3000
                });
                if (res.data.success === true) {
                    history(`/${module}`);
                }
            }).catch(err => {
                const unauthorizedError = 401;
                const notFoundError = 404;
                if ( err.response.status === unauthorizedError ) {
                    if ( !err.response.data.hasOwnProperty("action") ) {
                        Cookies.remove('JWT');
                        Cookies.remove('user');
                        Cookies.remove('rank');
                        Cookies.remove('displayname');
                        window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                    } else {
                        window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                    }
                } else if ( err.response.status === notFoundError ) {
                    window.location.href = `/notfound`;
                }
            });
        } else {
            window.location.href = `/${module}`;
        }
    };

    const retrieveData = async () => {
        await axios.get(
            `/rest/${module}/${action}/${identifier}`,
            {
                baseURL: `${window.generator.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        let canSaveAndAction = data.hasOwnProperty('can_save_and_action') ? data['can_save_and_action'] : null;
        let content_form = <AauComponentForm
            key={`generator_form_${module}_${identifier}`}
            columns={data['columns']}
            item={data['item']}
            readonly={action === 'view' || data['can_edit'] === false ? true : false}
            saveHandler={action === 'view' ? null : formData => submitHandler(formData, identifier, "save", data.btn_save)}
            saveActionHandler={canSaveAndAction === null ? null : formData => submitHandler(formData, identifier, "saveAndPush", data.btn_save)}
            saveAndActionLabel={canSaveAndAction !== null ? data['save_and_action_label'] : 'save and ' + canSaveAndAction}
            templateVersion={data['template_version']}
            template={data['template_form']}
            lang={props.lang}
        />;

        content = <div className={`p-2 pl-2 pr-5`}>
            {
                data['extra_information'] !== null && dataExtra === true && <TabList selectedValue={selectedValue} onTabSelect={onTabSelect} appearance="subtle">
                    <Tab value={`form`}>Formulaire</Tab>
                    {
                        Object.keys(data['extra_information']).map(kid => {
                            return <Tab value={`${data['extra_information'][kid]['id']}`} >
                                {data['extra_information'][kid]['title']}
                            </Tab>;
                        })
                    }
                </TabList>
            }
            <div className={`mt-3`}>
                <div
                    style={{
                        display: selectedValue === 'form' ? 'block' : 'none'
                    }}
                >
                    {content_form}
                </div>
                {
                    data['extra_information'] !== null && Object.keys(data['extra_information']).map(kid => {
                        let content_extra = null;

                        if ( data['extra_information'][kid]['id'] === selectedValue ) {
                            switch (data['extra_information'][kid]['kind'].toLowerCase()) {
                                case 'yaml':
                                    content_extra = <AauMixinStrYaml value={data['extra_information'][kid]['value'].replaceAll("\\\n", "").replaceAll('\\n', '\n').replaceAll('\\"', '\"').replaceAll('\\t', '  ').replace('\s\s\s', '').replace('\\ ', '')} />;
                                    break
                                case 'text':
                                    content_extra = <AauComponentCode
                                        value={data['extra_information'][kid]['value']}
                                        language={data['extra_information'][kid]['filetype']}
                                        wrapLines={true}
                                    />;
                                    break;
                                case 'json':
                                    content_extra = <AauMixinJson
                                        value={data['extra_information'][kid]['value']}
                                        height={700}
                                        treemode={true}
                                    />
                                    break;
                            }
                        }

                        return content_extra
                    })
                }
            </div>
        </div>;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='mt-23 p-2 pl-2 pr-15' style={{backgroundColor: tokens.colorNeutralBackground1}}>
                {content}
            </div>
            <AauMixinFabBar
                back={`/${module}`}
                lang={props.lang}
                urlBasePath={`${module}`}
                fabs={{
                    view: {
                        enabled: data !== null && data !== false && data['can_view'] && window.location.pathname.indexOf('/edit/') !== -1,
                        confirm: false,
                        label: props.lang.getText('view'),
                        component: {
                            confirm: false,
                            icon: 'EyeTracking24Filled',
                            url: `/view/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    },
                    edit: {
                        enabled: data !== null && data !== false && data['can_edit'] && window.location.pathname.indexOf('/view/') !== -1,
                        confirm: false,
                        label: props.lang.getText('edit'),
                        component: {
                            confirm: false,
                            icon: 'Edit24Regular',
                            url: `/edit/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    },
                    history: {
                        enabled: data !== null && data !== false && data['can_history_compare'] ,
                        confirm: false,
                        label: props.lang.getText('history'),
                        component: {
                            icon: 'History24Filled',
                            url: `/history/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    }
                }}
            />
        </div>
    );
};
AauPageForm.defaultProps = defaultProps;
