/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {
    Tooltip,
    Toolbar,
    ToolbarButton,
    ToolbarDivider,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle,
    Button,
    DialogContent,
    DialogActions,
    Dialog,
    useToastController,
    Toast,
    ToastTitle, Spinner, ToastBody
} from "@fluentui/react-components";
import {Dismiss24Regular} from "@fluentui/react-icons";
import {casdoor} from '../../casdoor';
import {tokens} from "@fluentui/react-theme";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";
import axios from 'axios';
import Cookies from 'js-cookie';


export interface AauMixinFabBarProps {
    lang: AauToolsLang;
    create?: string;
    back?: string;
    urlBasePath?: string;
    fabs?: object
}

const defaultProps = {
    create: null,
    back: null,
    urlBasePath: '',
    fabs: null
} as AauMixinFabBarProps;

export const AauMixinFabBar: FC<AauMixinFabBarProps> = props => {

    const {dispatchToast, updateToast} = useToastController('askanduse_toaster');

    const fabStyles= {
        border: '1px',
        borderColor: tokens.colorNeutralStroke2,
        borderStyle: 'solid'
    }

    return <Toolbar
        key={`toolbar_fab`}
        size={'small'}
        aria-label="Global Fab Toolbar"
        vertical={true}
        style={{
            position: 'fixed',
            right: '10px',
            bottom: '18px',
            zIndex: 1000,
            backgroundColor: tokens.colorNeutralShadowKeyDarker
        }}
    >
        {
            props.fabs !== null ? Object.keys(props.fabs).map((key, i) => {
                var btn = props.fabs[key];

                return (!btn.hasOwnProperty('enabled') || btn.hasOwnProperty('enabled') && btn['enabled'] === true) ? (
                    btn['component']['confirm'] === false ? <Tooltip key={`fab_${key}`} content={btn['label']} relationship="description">
                        <ToolbarButton
                            key={`fab_btn_${key}`}
                            as={'button'}
                            icon={<AauMixinIcon icon={btn['component']['icon']} style={`fab`} />}
                            style={fabStyles}
                            onClick={() => {
                                if ( btn['mixin'] === 'btn_link_with_url' ) {
                                    window.open(btn['component']['url'], '_blank')
                                } else if ( btn['mixin'] === 'btn_link_page_id' ) {
                                    window.location.href = `${window.generator.FRONTURL}/${props.urlBasePath}${ btn['component'].hasOwnProperty('url') ? btn['component']['url'] : `/${key}` }`
                                } else if ( btn['mixin'] === 'btn_link_action_id' ) {
                                    dispatchToast(
                                        <Toast appearance="inverted">
                                            <ToastTitle media={<Spinner size="tiny" />}>
                                                {btn["i18n"]["progress"]}
                                            </ToastTitle>
                                        </Toast>,
                                {
                                            toastId: `fab_${key}`,
                                            timeout: -1
                                        }
                                    );
                                    axios.get(
                                    `/rest/${props.urlBasePath}${ btn['component'].hasOwnProperty('url') ? btn['component']['url'] : `/${key}`}`,
                                    {
                                            baseURL: `${window.generator.BACKURL}`,
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                            }
                                        }
                                    ).then(res => {
                                        updateToast({
                                            content: <Toast appearance="inverted">
                                                <ToastTitle>
                                                    {btn["i18n"][res.data.success === true ? "success" : "error"]}
                                                </ToastTitle>
                                                <ToastBody>
                                                    {res.data.action_return}
                                                </ToastBody>
                                            </Toast>,
                                            toastId: `fab_${key}`,
                                            intent: res.data.success === true ? "success" : "error",
                                            timeout: 3000
                                        });
                                    }).catch(err => {
                                        const unauthorizedError = 401;
                                        if (err.response.data['code'] === unauthorizedError) {
                                            Cookies.remove('JWT');
                                            Cookies.remove('user');
                                            Cookies.remove('rank');
                                            Cookies.remove('displayname');
                                            window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                                        } else {
                                            updateToast({
                                                content: <Toast appearance="inverted">
                                                    <ToastTitle>{props.lang.getError(err.response.data['code']).replace('%s', '')}</ToastTitle>
                                                </Toast>,
                                                toastId: `fab_${key}`,
                                                intent: "error",
                                                timeout: 3000
                                            });
                                        }
                                    });
                                }
                            }}
                        />
                    </Tooltip> : <Dialog modalType={`alert`} key={`fab_${key}_confirm`}>
                        <DialogTrigger disableButtonEnhancement>
                            <Tooltip key={`fab_${key}`} content={btn['label']} relationship="description">
                                <ToolbarButton
                                    key={`fab_btn_${key}`}
                                    as={'button'}
                                    icon={<AauMixinIcon icon={btn['component']['icon']} style={`fab`} />}
                                    style={fabStyles}
                                />
                            </Tooltip>
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle
                                    action={
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<Dismiss24Regular />}
                                            />
                                        </DialogTrigger>
                                    }
                                >
                                    {window.generator.SITENAME}
                                </DialogTitle>
                                <DialogContent>
                                    {props.lang.getText('dialog_confirm_text')}
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary">{props.lang.getBtn('cancel')}</Button>
                                    </DialogTrigger>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="primary" onClick={() => {
                                            dispatchToast(
                                                <Toast appearance="inverted">
                                                    <ToastTitle media={<Spinner size="tiny" />}>
                                                        {btn["i18n"]["progress"]}
                                                    </ToastTitle>
                                                </Toast>,
                                        {
                                                    toastId: `fab_${key}`,
                                                    timeout: -1
                                                }
                                            );
                                            axios.get(
                                            `/rest/${props.urlBasePath}${ btn['component'].hasOwnProperty('url') ? btn['component']['url'] : `/${key}`}`,
                                            {
                                                    baseURL: `${window.generator.BACKURL}`,
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                    }
                                                }
                                            ).then(res => {
                                                updateToast({
                                                    content: <Toast appearance="inverted">
                                                        <ToastTitle>
                                                            {btn["i18n"][res.data.success === true ? "success" : "error"]}
                                                        </ToastTitle>
                                                        <ToastBody>
                                                            {res.data.action_return}
                                                        </ToastBody>
                                                    </Toast>,
                                                    toastId: `fab_${key}`,
                                                    intent: res.data.success === true ? "success" : "error",
                                                    timeout: 3000
                                                });
                                            }).catch(err => {
                                                const unauthorizedError = 401;
                                                if (err.response.data['code'] === unauthorizedError) {
                                                    Cookies.remove('JWT');
                                                    Cookies.remove('user');
                                                    Cookies.remove('rank');
                                                    Cookies.remove('displayname');
                                                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                                                } else {
                                                    updateToast({
                                                        content: <Toast appearance="inverted">
                                                            <ToastTitle>{props.lang.getError(err.response.data['code']).replace('%s', '')}</ToastTitle>
                                                        </Toast>,
                                                        toastId: `fab_${key}`,
                                                        intent: "error",
                                                        timeout: 3000
                                                    });
                                                }
                                            });
                                        }}>
                                            {props.lang.getBtn('confirm')}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                ) : null
            }) : null
        }
        {
            props.create !== null ? <Tooltip key={`fab_create`} content={props.lang.getBtn('create')} relationship="description">
                <ToolbarButton
                    key={`fab_btn_create`}
                    as={'button'}
                    icon={<AauMixinIcon icon='Add24Filled' style={`fab`} />}
                    style={fabStyles}
                    onClick={() => window.location.href = props.create}
                />
            </Tooltip> : null
        }
        {
            props.back !== null ? <Tooltip key={`fab_go_back`} content={props.lang.getBtn('go_back')} relationship="description">
                <ToolbarButton
                    key={`fab_btn_go_back`}
                    as={'button'}
                    icon={<AauMixinIcon icon='ArrowReset24Filled' style={`fab`} />}
                    style={fabStyles}
                    onClick={() => window.location.href = props.back}
                />
            </Tooltip> : null
        }
        <ToolbarDivider />
        <Tooltip key={`fab_go_to_up`} content={props.lang.getBtn('go_to_up')} relationship="description">
            <ToolbarButton
                key={`fab_btn_go_to_up`}
                as={'button'}
                icon={<AauMixinIcon icon='PaddingTop24Filled' style={`fab`} />}
                style={fabStyles}
                onClick={() => window.scrollTo(0, 0)}
            />
        </Tooltip>
        <Tooltip key={`fab_go_to_down`} content={props.lang.getBtn('go_to_down')} relationship="description">
            <ToolbarButton
                key={`fab_btn_go_to_down`}
                as={'button'}
                icon={<AauMixinIcon icon='PaddingDown24Filled'style={`fab`} />}
                style={fabStyles}
                onClick={() => window.scroll(0, document.body.scrollHeight)}
            />
        </Tooltip>

    </Toolbar>
};
AauMixinFabBar.defaultProps = defaultProps;
