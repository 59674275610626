/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Helmet} from 'react-helmet-async';

import {tokens} from "@fluentui/react-theme";
import {Subtitle1, Button, Dialog, DialogTrigger, DialogTitle, DialogBody, DialogContent, DialogSurface,
        DialogActions} from "@fluentui/react-components";

import {BookInformation20Regular} from "@fluentui/react-icons";
import {AauMixinBadgeVersion} from "../aauMixinBadgeVersion/aauMixinBadgeVersion";

export interface AauMixinPageHeaderProps {
    pageTitle: string;
    pageHelp: string;
    menuBadgeText?: string;
    menuBadgeState?: string;
}

const defaultProps = {
    menuBadgeText: null,
    menuBadgeState: null,
} as AauMixinPageHeaderProps;

export const AauMixinPageHeader: FC<AauMixinPageHeaderProps> = props => {
    return <div
        className={`float-clear width-100 pos-fixed top-px-48 z-310`}
        style={{backgroundColor: tokens.colorNeutralBackground1}}
    >
        <Helmet>
            <title>{window.generator.SITENAME}</title>
            <link rel='canonical' href={window.location.href} />
        </Helmet>
        <div className={`width-100 pt-2 pb-2 text-center z-311`} style={{backgroundColor: tokens.colorNeutralShadowKey}}>
            <div className={`fit-content mx-auto`}>
                <Subtitle1 align="center">
                    {props.pageTitle}
                    {
                        props.menuBadgeText !== null && <AauMixinBadgeVersion
                            text={props.menuBadgeText}
                            state={props.menuBadgeState}
                        />
                    }
                    {
                        props.pageHelp !== null && <Dialog>
                            <DialogTrigger disableButtonEnhancement>
                                <BookInformation20Regular />
                            </DialogTrigger>
                            <DialogSurface>
                                <DialogBody>
                                    <DialogTitle action={null}>{props.pageTitle}</DialogTitle>
                                    <DialogContent>
                                        {props.pageHelp}
                                    </DialogContent>
                                    <DialogActions>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button appearance="primary">Close</Button>
                                        </DialogTrigger>
                                    </DialogActions>
                                </DialogBody>
                            </DialogSurface>
                        </Dialog>
                    }
                </Subtitle1>
            </div>
        </div>
    </div>;
};
AauMixinPageHeader.defaultProps = defaultProps;
