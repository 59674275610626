/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import AauToolsLang from '../aauToolsLang/aauToolsLang';

export interface AauComponentTableColumnProps {
    showFilter?: boolean;
    name: string;
    label: string;
    mixin: string;
    enums: object;
    width: number,
    filter: string | null;
    filterValue?: string | number | boolean | object;
    component: object;
    primary: boolean;
    state: boolean;
    title: string;
    setFilters: any;
    sort: string;
    sortSetColumn?: any;
    columns?: AauComponentTableColumnProps[];
    parentSetColumns?: any;
    lang?: AauToolsLang;
    data?: object[];
}

export const AauComponentTableColumnPropsDefault = {
    label: '',
    showFilter: null,
    sort: 'none',
    data: []
} as AauComponentTableColumnProps;
