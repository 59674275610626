/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauComponentTableColumnProps} from './tableHeadColumn.d';
import {AauComponentTableHeadRow} from './tableHeadRow';
import {tokens} from "@fluentui/react-theme";


export interface AauComponentTableHeadProps {
    columns: AauComponentTableColumnProps[];
    parentSetColumns: any;
    sortSetColumn: any;
    lang: AauToolsLang;
    data?: object[];
}

const defaultProps = {
    columns: [],
    data: []
} as AauComponentTableHeadProps;

export const AauComponentTableHead: FC<AauComponentTableHeadProps> = props => {
    return <thead style={{backgroundColor: tokens.colorPalettePlatinumBackground2, color: tokens.colorNeutralForeground4}}>
        <AauComponentTableHeadRow
            key='th_name'
            rowId='th_name'
            data={props.data}
            columns={props.columns}
            showFilter={false}
            parentSetColumns={props.parentSetColumns}
            sortSetColumn={props.sortSetColumn}
            lang={props.lang}
        />
        <AauComponentTableHeadRow
            key='th_filters'
            rowId='th_filters'
            data={props.data}
            columns={props.columns}
            showFilter={true}
            parentSetColumns={props.parentSetColumns}
            sortSetColumn={props.sortSetColumn}
            lang={props.lang}
        />
    </thead>;
};
AauComponentTableHead.defaultProps = defaultProps;
