/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {tokens} from '@fluentui/react-theme';
import {Caption2} from '@fluentui/react-components';
import {CheckmarkCircle16Filled, Warning16Filled, ShieldError16Filled} from '@fluentui/react-icons';


export interface AauMixinBadgeProps {
    less?: number;
    greater?: number;
    precision?: number;
    value: number;
}

const defaultProps = {
    less: 40,
    greater: 70,
    precision: 2
} as AauMixinBadgeProps;

export const AauMixinBadge: FC<AauMixinBadgeProps> = props => {
    let bgColor = tokens.colorPaletteBeigeBorderActive;
    let cssIcon = <Warning16Filled style={{position: 'relative', bottom: -4}} />;

    if ( props.value < props.less ) {
        bgColor = tokens.colorPaletteRedBorderActive;
        cssIcon = <ShieldError16Filled style={{position: 'relative', bottom: -4}} />;
    } else if ( props.value > props.greater ) {
        bgColor = tokens.colorPaletteGreenBorderActive;
        cssIcon = <CheckmarkCircle16Filled style={{position: 'relative', bottom: -4}} />;
    }

    return <Caption2 key={`nextId()`} style={{padding: 4, minWidth: 250, backgroundColor: bgColor, color: tokens.colorNeutralForegroundInverted}}>
        {cssIcon} {props.value.toFixed(props.precision)}
    </Caption2>;
};
AauMixinBadge.defaultProps = defaultProps;