/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {AauFormLabel} from '../aauFormLabel/aauFormLabel';
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormUploadProps {
    label?: string;
    readonly: boolean;
    itemKey: string;
    key: string;
    name: string;
    value?: string | number;
    validator: object;
    parentFunction?: any;
    required?: boolean | null;
    hint?: string;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: '',
    required: false,
    hint: null
} as AauFormUploadProps;

export const AauFormUpload: FC<AauFormUploadProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const getFiles = e => {
        const files = e.target.files;
        const file = files[0];

        const reader = new FileReader();

        reader.readAsDataURL(files[0])
        reader.onload = () => {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + ' kB',
                base64: reader.result,
                file: file,
            };

            validateField(fileInfo.base64.toString().split(';')[1].split(',')[1]);
        }

    };

    let content;
    const {readonly, itemKey, name, value, parentFunction, required, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    if ( readonly === false) {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                // isValid={state['isValid']}
                name={name}
                value={label}
                // readonly={false}
                required={required}
                // helper={helper}
            />
            <div className='mt-3'>
                <input
                    id={itemKey}
                    key={`${itemKey}-ctrl`}
                    name={name}
                    type="file"
                    onChange={getFiles.bind(this)}
                />
            </div>
        </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                // isValid={state['isValid']}
                name={name}
                value={label}
                // readonly={true}
                required={required}
                // helper={helper}
            />
            <div className={`view-value pt-5`}>
                {state['content']}
            </div>
        </div>;
    }

    return content;
};
AauFormUpload.defaultProps = defaultProps;
