/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC, Component} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {AauComponentTable} from '../aauComponentTable/aauComponentTable';
import {AauMixinFabBar} from "../aauMixinFabBar/aauMixinFabBar";
import {casdoor} from '../../casdoor';


export interface AauPageListProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageListProps;

export const AauPageList: FC<AauPageListProps> = props => {
    const {lang} = props;
    const {module} = useParams();
    const [data, setData] = useState(null);
    // const [isPanelOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false);
    // const [dataPanel, setDatapanel] = useState(null);

    const retrieveData = async () => {
        await axios.get(
            `/rest/${module}`,
            {
                baseURL: `${window.generator.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    // const handlerPanelInfo = async (url:string) => {
    //     await axios.get(
    //         `/rest/${url}`,
    //         {
    //             baseURL: `${window.generator.BACKURL}`,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'JWT '.concat(Cookies.get('JWT'))
    //             }
    //         }
    //     ).then(res => {
    //         setDatapanel(res.data);
    //         openPanel();
    //     }).catch(err => {
    //         const unauthorizedError = 401;
    //         if ( err.response.status === unauthorizedError ) {
    //             Cookies.remove('JWT');
    //             Cookies.remove('user');
    //             Cookies.remove('rank');
    //             Cookies.remove('displayname');
    //             window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
    //         }
    //     });
    // }

    // let content = <AauMixinLoader />;
    // let panelContent = null;

   // let  onRenderNavigationContent: IRenderFunction<IPanelProps> = React.useCallback((props, defaultRender) => (<>{defaultRender!(props)}</>),[]);
   //  let  panelTitle = null;
    if ( data !== null && data !== false ) {
    //     // let canSaveAndAction = data.hasOwnProperty('can_save_and_action') ? data['can_save_and_action'] : null;
    //     // content = ;
    //
    //     // const options = ['Cat', 'Dog', 'Ferret', 'Fish', 'Hamster', 'Snake'];
    //     //
    //     // content =  <div className='mt-40'>
    //     //     <Combobox placeholder="Select an animal">
    //     //         {options.map(option => (
    //     //             <Option key={option} disabled={option === 'Ferret'}>
    //     //                 {option}
    //     //             </Option>
    //     //         ))}
    //     //     </Combobox>
    //     // </div>
    //
    //     // if ( dataPanel !== null ) {
    //     //     let btns = dataPanel.hasOwnProperty('columns' ) && dataPanel['columns'][dataPanel['columns'].length -1]['name'] === 'btnactiongroup' ? dataPanel['columns'][dataPanel['columns'].length -1]['component'] : null;
    //     //
    //     //     panelTitle = dataPanel['name'];
    //     //
    //     //     // onRenderNavigationContent = React.useCallback(
    //     //     //     (props, defaultRender) => (
    //     //     //         <>
    //     //     //         {
    //     //     //             btns !== null && <AauMixinBtnGroup
    //     //     //                 btns={btns.hasOwnProperty('grpItems') ? btns['grpItems'] : {}}
    //     //     //                 dataJson={dataPanel['item']}
    //     //     //                 size='tinyiest'
    //     //     //                 align='right'
    //     //     //                 columnid={dataPanel['columnid']}
    //     //     //                 lang={lang}
    //     //     //                 rankField={btns.hasOwnProperty('rank_field') ? btns['rank_field'] : null}
    //     //     //                 optionalMode={false}
    //     //     //             />
    //     //     //         }
    //     //     //         {
    //     //     //             // This custom navigation still renders the close button (defaultRender).
    //     //     //             // If you don't use defaultRender, be sure to provide some other way to close the panel.
    //     //     //             defaultRender!(props)
    //     //     //         }
    //     //     //         </>
    //     //     //     ),
    //     //     //     [],
    //     //     // );
    //     //     //
    //     //     // panelContent =  dataPanel !== null && <AauComponentForm
    //     //     //     className={'float-left'}
    //     //     //     columns={dataPanel['columns']}
    //     //     //     item={dataPanel['item']}
    //     //     //     readonly={true}
    //     //     //     saveHandler={null}
    //     //     //     lang={lang}
    //     //     // />
    //     // }
    //
        //NOSONAR
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    // const fabStartHeight = 210;
    // const fabHeight = 50;

    return (
        <div>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            {
                data !== null && data !== false ? <AauComponentTable
                    className="mt-23 p-2 pl-2 pr-15"
                    columns={data['columns']}
                    data={data['info']['data']}
                    columnid={data['columnid']}
                    displayRows={data['info'].hasOwnProperty('options') ? data['info']['options']['showEntries'] : 15}
                    displayRowsList={data['info'].hasOwnProperty('options') ? data['info']['options']['showEntriesList'] : [15,25,50,100]}
                    refreshTable={retrieveData}
                    lang={lang}
                    canEdit={data.hasOwnProperty('can_edit') ? data['can_edit'] : false}
                    canView={data.hasOwnProperty('can_view') ? data['can_view'] : false}
                    canSaveAndAction={data.hasOwnProperty('can_save_and_action') ? data['can_save_and_action'] : null}
                    saveAndActionLabel={data.hasOwnProperty('save_and_action_label') ? data['save_and_action_label'] : 'save and ' + (data.hasOwnProperty('can_save_and_action') ? data['can_save_and_action'] : null)}
                    btnSave={data.hasOwnProperty('btn_save') ? data['btn_save'] : {}}
                    viewAction={data.hasOwnProperty('table_view_action') ? data['table_view_action'] : 'view'}
                    handlerPanelInfo={null}
                /> : <AauMixinLoader />
            }
            <AauMixinFabBar
                create={data !== null && data !== false && data['can_create'] ? `/${module}/create/0` : null}
                lang={props.lang}
                urlBasePath={`${module}`}
                fabs={data !== null && data !== false ? data['fabs'] : null}
            />
        </div>
    );
};
AauPageList.defaultProps = defaultProps;
