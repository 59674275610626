/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import Cookies from 'js-cookie';

import {AauComponentTableColumnProps} from './tableHeadColumn.d';

import {AauComponentTableHead} from './tableHead';
import {AauComponentTableBody} from './tableBody';
import {AauComponentTablesNavigation} from './tableNavigation';

import * as _ from 'lodash';


export interface AauComponentTableProps {
    columns: object[];
    data: object[];
    columnid: string;
    displayRows?: number;
    displayRowsList?: number[];
    canEdit?: boolean;
    canView?: boolean;
    canSaveAndAction?: string|null;
    saveAndActionLabel?: string;
    viewAction?: string;
    lang: AauToolsLang;
    refreshTable?: () => void;
    handlerPanelInfo?: (url:string) => void;
    className?: string;
    btnSave: object;
}

const defaultProps = {
    displayRows: null,
    viewAction: 'view',
    canSaveAndAction: null,
    saveAndActionLabel: 'Save and Action',
    displayRowsList: null,
    canEdit: false,
    canView: false,
    className: '',
    btnSave: {}
} as AauComponentTableProps;

export const AauComponentTable: FC<AauComponentTableProps> = props => {
    const [pageIndexState, setPageIndexState] = useState<number>(1);

    const setSort = (name, way):void => {
        const profil = JSON.parse(localStorage.getItem(Cookies.get('user')));
        const savedModuleFilter = profil['filters'].hasOwnProperty(window.location.href) ? profil['filters'][window.location.href] : {};

        Object.keys(columnsDisplayState).forEach(cid => {
            if (!savedModuleFilter.hasOwnProperty(columnsDisplayState[cid]['name'])) {
                savedModuleFilter[columnsDisplayState[cid]['name']] = {};
            }
            if ( columnsDisplayState[cid]['name'] === name ) {
                savedModuleFilter[columnsDisplayState[cid]['name']]['sort'] = way;
            } else {
                savedModuleFilter[columnsDisplayState[cid]['name']]['sort'] = 'none';
            }
        });
        profil['filters'][window.location.href] = savedModuleFilter;

        localStorage.setItem(Cookies.get('user'), JSON.stringify(profil));
        setSortColumn([name, way]);
        setPageIndexState(1);
        setColumnsDisplayState(defineColumns());
    }
    const setColumns = (value):void => {
        const profil = JSON.parse(localStorage.getItem(Cookies.get('user')));

        if ( value !== 'clearColumns' ) {
            const savedModuleFilter = profil['filters'].hasOwnProperty(window.location.href) ? profil['filters'][window.location.href] : {};
            Object.keys(columnsDisplayState).forEach(cid => {
                if (!savedModuleFilter.hasOwnProperty(columnsDisplayState[cid]['name'])) {
                    savedModuleFilter[columnsDisplayState[cid]['name']] = {};
                }
                savedModuleFilter[columnsDisplayState[cid]['name']]['state'] = value.indexOf(columnsDisplayState[cid]['name']) !== -1;
            });
            profil['filters'][window.location.href] = savedModuleFilter;
        } else {
            profil['filters'][window.location.href] = {};
        }
        localStorage.setItem(Cookies.get('user'), JSON.stringify(profil));
        setColumnsDisplayState(defineColumns());
    };

    const defineSort = ():string[] => {
        const savedFiltersLoad = JSON.parse(localStorage.getItem(Cookies.get('user')))['filters'];
        const savedModuleFilterLoad = savedFiltersLoad.hasOwnProperty(window.location.href) ? savedFiltersLoad[window.location.href] : {};

        let ret = null;

        Object.keys(props.columns).forEach(key => {
            const c = props.columns[key];

            if (ret === null && savedModuleFilterLoad.hasOwnProperty(c['name']) && savedModuleFilterLoad[c['name']].hasOwnProperty('sort') && savedModuleFilterLoad[c['name']]['sort'] !== 'none') {
                ret = [c['name'], savedModuleFilterLoad[c['name']]['sort']];
            }
        });

        return ret === null ? ['', 'asc'] : ret;
    };

    const defineColumns = (): AauComponentTableColumnProps[] => {
        const savedFiltersLoad = JSON.parse(localStorage.getItem(Cookies.get('user')))['filters'];
        const savedModuleFilterLoad = savedFiltersLoad.hasOwnProperty(window.location.href) ? savedFiltersLoad[window.location.href] : {};

        const ret:AauComponentTableColumnProps[] = [];
        Object.keys(props.columns).forEach(key => {
            const c = props.columns[key];

             const co = c.hasOwnProperty('component') ? c['component'] : {};
            // const o = c.hasOwnProperty('options') ? c['options'] : {};
            const optional = co.hasOwnProperty('optional') && co['optional'] === true;
            const optionnalSmall = (co.hasOwnProperty('optional_small') && co['optional_small'] === true) || (!co.hasOwnProperty('optionalsmall') && ['id', 'state', 'updatedBy', 'updatedAt', 'createdBy', 'createdAt'].includes(c['name']));
            let displayOptional  = document.body.offsetWidth < parseInt(window.generator.SCREENSMALLWIDTH) ? (optionnalSmall || optional) : optional;
            displayOptional = (savedModuleFilterLoad.hasOwnProperty(c['name']) && savedModuleFilterLoad[c['name']].hasOwnProperty('state')) ? savedModuleFilterLoad[c['name']]['state'] : !displayOptional;

            c['width'] = c.hasOwnProperty('width') ? c['width'] : null;
            c['sort'] = (savedModuleFilterLoad.hasOwnProperty(c['name']) && savedModuleFilterLoad[c['name']].hasOwnProperty('sort')) ? savedModuleFilterLoad[c['name']]['sort'] : 'none';
            c['state'] = displayOptional;
            c['filterValue'] = (savedModuleFilterLoad.hasOwnProperty(c['name']) && savedModuleFilterLoad[c['name']].hasOwnProperty('value')) ? savedModuleFilterLoad[c['name']]['value'] : null;
            c['title'] = c['label'];
            c['setFilters'] = (cname, value, isPrimary=false, multi:boolean=false):void => {
                const savedFiltersLoad = JSON.parse(localStorage.getItem(Cookies.get('user')))['filters'];
                const savedModuleFilterLoadTmp = savedFiltersLoad.hasOwnProperty(window.location.href) ? savedFiltersLoad[window.location.href] : {};

                const columnsDisplayStateTmp = [...columnsDisplayState];

                const cnameTmp = cname.split('@')[0];

                const profil = JSON.parse(localStorage.getItem(Cookies.get('user')));
                let savedModuleFilter = profil['filters'].hasOwnProperty(window.location.href) ? profil['filters'][window.location.href] : {};

                if ( isPrimary === true) {
                    savedModuleFilter = {};
                }
                if ( !savedModuleFilter.hasOwnProperty(cnameTmp) ) {
                    savedModuleFilter[cnameTmp] = {};
                }

                let valTmp = value;
                if ( cname.indexOf('@from') !== -1 || cname.indexOf('@to') !== -1 ) {
                    valTmp = new Date(value).getTime();
                }

                if ( cname.indexOf('@') !== -1 ) {
                    if ( !savedModuleFilter[cnameTmp].hasOwnProperty('value') ) {
                        savedModuleFilter[cnameTmp]['value'] = {};
                    }
                    if ( valTmp === null ) {
                        delete savedModuleFilter[cnameTmp]['value'][cname.split('@')[1]];
                    } else {
                        savedModuleFilter[cnameTmp]['value'][cname.split('@')[1]] = valTmp;
                    }
                // } else if ( multi ) {
                //     if ( !savedModuleFilter[cnameTmp].hasOwnProperty('value') || savedModuleFilter[cnameTmp]['value'] === null ) {
                //         savedModuleFilter[cnameTmp]['value'] = [];
                //     }
                //
                //     if ( savedModuleFilter[cnameTmp]['value'].includes(valTmp) ) {
                //         savedModuleFilter[cnameTmp]['value'] = savedModuleFilter[cnameTmp]['value'].filter(o => o !== valTmp);
                //     } else {
                //         savedModuleFilter[cnameTmp]['value'].push(valTmp);
                //     }
                //
                //     savedModuleFilter[cnameTmp]['value'] = savedModuleFilter[cnameTmp]['value'].filter(o => o !== '');
                //     if ( savedModuleFilter[cnameTmp]['value'].length < 1 ) {
                //         savedModuleFilter[cnameTmp]['value'] = null;
                //     }
                } else {
                    savedModuleFilter[cnameTmp]['value'] = valTmp;
                }

                Object.keys(columnsDisplayState).forEach(cid => {
                    let filterValueTmp = (savedModuleFilter.hasOwnProperty(columnsDisplayState[cid]['name']) && savedModuleFilter[columnsDisplayState[cid]['name']].hasOwnProperty('value')) ? savedModuleFilter[columnsDisplayState[cid]['name']]['value'] : null;

                    if ( !isPrimary && columnsDisplayState[cid]['primary'] ) {
                        filterValueTmp = null;
                    }

                    columnsDisplayStateTmp[cid]['filterValue'] = filterValueTmp;
                    if ( savedModuleFilterLoadTmp.hasOwnProperty(columnsDisplayState[cid]['name']) && savedModuleFilterLoadTmp[columnsDisplayState[cid]['name']].hasOwnProperty('state') ) {
                        columnsDisplayStateTmp[cid]['state'] = savedModuleFilterLoadTmp[columnsDisplayState[cid]['name']]['state'];
                    }
                });

                profil['filters'][window.location.href] = savedModuleFilter;
                localStorage.setItem(Cookies.get('user'), JSON.stringify(profil));

                setColumnsDisplayState(columnsDisplayStateTmp);
                setPageIndexState(1);
            };

            ret.push(c)

        });

        return ret;
    };

    const [displayRowsValue, setDisplayRowsValue] = useState<number>(props.displayRows);

    const [sortColumn, setSortColumn] = useState<string[]>(defineSort());
    const [columnsDisplayState, setColumnsDisplayState] = useState<AauComponentTableColumnProps[] | null>(defineColumns());

    let datapart:object[] = props.data;
    for( const cid in Object.keys(columnsDisplayState)) {
        const c = columnsDisplayState[cid];

        if (c['state'] === true && c['filter'] !== null && c['filterValue'] !== null && c['filterValue'] !== '') {
            datapart = datapart.filter(e => {
                let ret = false;
                let itemValueTmp = e[c['name']];
                itemValueTmp = typeof(itemValueTmp) === 'object' ? JSON.stringify(itemValueTmp) : itemValueTmp;

                if ( typeof(c['filterValue']) !== 'undefined' && typeof(itemValueTmp) === "undefined" ) {
                    ret = false;
                } else {
                    switch (c['filter']) {
                        case 'textField':
                            if (typeof (c['filterValue']) !== 'undefined') {
                                try {
                                    let re = new RegExp(c['filterValue'].toString(), 'gi');
                                    ret = itemValueTmp.toString().search(re) !== -1;
                                } catch (error) {
                                    ret = itemValueTmp.toString().indexOf(c['filterValue'].toString()) !== -1;
                                }
                            } else {
                                ret = true;
                            }
                            break;
                        case 'select':
                        case 'persona':
                            c['filterValue'] = c['filterValue'] !== null && typeof(c['filterValue'], Array) ? Array(c['filterValue']).join(',') : c['filterValue'];
                            if (typeof (c['filterValue']) !== 'undefined' && c['filterValue'] !== '_NOFILTER_') {
                                try {
                                    ret = (itemValueTmp.toString().toLowerCase() === c['filterValue'].toString().toLowerCase())
                                } catch (error) {
                                    ret = itemValueTmp.toString().indexOf(c['filterValue'].toString()) !== -1;
                                }
                            } else {
                                ret = true;
                            }
                            break;
                        case 'multiselect':
                            let isFilteredTmp = true;

                            let itemValueTmp2 = typeof (itemValueTmp) === "number" ? [itemValueTmp.toString()].join(',') : itemValueTmp;

                            Object.keys(itemValueTmp2).forEach(dn => {
                                if (isFilteredTmp === true) {
                                    isFilteredTmp = c['filterValue'].toString().split(',').indexOf(itemValueTmp2.split(',')[dn]) === -1;
                                }
                            });
                            ret = !isFilteredTmp;
                            break;
                        case 'rangedate':
                            const dfrom = c['filterValue'].hasOwnProperty('from') ? c['filterValue']['from'] : null;
                            const dto = c['filterValue'].hasOwnProperty('from') ? c['filterValue']['to'] : null;
                            const ddate = new Date(itemValueTmp);

                            if (typeof (dfrom) !== 'undefined' && typeof (dto) !== 'undefined') {
                                ret = (dfrom <= ddate.getTime() && dto >= ddate.getTime()) ? true : false;
                            } else if (typeof (dfrom) !== 'undefined') {
                                ret = (dfrom <= ddate.getTime()) ? true : false;
                            } else if (typeof (dto) !== 'undefined') {
                                ret = (dto >= ddate.getTime()) ? true : false;
                            } else {
                                ret = true;
                            }
                            break;
                        case 'rangeint':
                        case 'rangedint':
                            const dmin = c['filterValue'].hasOwnProperty('min') && !Number.isNaN(c['filterValue']['min']) ? c['filterValue']['min'] : null;
                            const dmax = c['filterValue'].hasOwnProperty('max') && !Number.isNaN(c['filterValue']['max']) ? c['filterValue']['max'] : null;

                            if (![null, ''].includes(dmin) && ![null, ''].includes(dmax)) {
                                ret = (dmin <= itemValueTmp && dmax >= itemValueTmp) ? true : false;
                            } else if (![null, ''].includes(dmin)) {
                                ret = (dmin <= itemValueTmp) ? true : false;
                            } else if (![null, ''].includes(dmax)) {
                                ret = (dmax >= itemValueTmp) ? true : false;
                            } else {
                                ret = true;
                            }
                            break;
                        default:
                        //NOSONAR
                    }
                }

                return ret;
            });
        }
    }

    for( const cid in Object.keys(columnsDisplayState)) {
        const c = columnsDisplayState[cid];
        if ( c['filter'] === 'select' ) {
            let items = datapart.map(e => e[c['name']]);

            items.filter((element, i) => i === items.indexOf(element)).forEach(item => {
                columnsDisplayState[cid]['component']['enums'][item] = columnsDisplayState[cid]['component']['enums'].hasOwnProperty(item) ? columnsDisplayState[cid]['component']['enums'][item] : item
            });
        } else if ( c['filter'] === 'multiselect' ) {
            let items = props.data.map(e => e[c['name']]);
            items = items.filter((element, i) => i === items.indexOf(element));
            let itemsTmp = [];
            items.forEach(item => {
                if ( typeof(item) === 'string' ) {
                    itemsTmp = itemsTmp.concat(item.toString().split(','));
                }
            });
            itemsTmp.filter((element, i) => i === itemsTmp.indexOf(element)).forEach(item => {
                columnsDisplayState[cid]['component']['enums'][item] = columnsDisplayState[cid]['component']['enums'].hasOwnProperty(item) ? columnsDisplayState[cid]['component']['enums'][item] : item
            });
        } else {
            //NOSONAR
        }
    }

    if ( sortColumn[0] !== '' ) {
        datapart = _.orderBy(datapart, [sortColumn[0]], [sortColumn[1] as 'asc' | 'desc']);
    }

    return <div className={`pos-relative z-5 width-auto pr-5 ${props.className}`}>
        <table style={{width: `calc(100% - 10px)`}}>
            <AauComponentTableHead
                key='table-head'
                data={datapart}
                columns={columnsDisplayState}
                parentSetColumns={setColumns}
                sortSetColumn={setSort}
                lang={props.lang}
            />
            <AauComponentTableBody
                key='table-body'
                columns={columnsDisplayState}
                data={datapart.slice((pageIndexState-1)*displayRowsValue, pageIndexState*displayRowsValue)}
                refreshTable={props.refreshTable}
                columnid={props.columnid}
                canView={props.canView}
                canSaveAndAction={props.canSaveAndAction}
                saveAndActionLabel={props.saveAndActionLabel}
                btnSave={props.btnSave}
                viewAction={props.viewAction}
                canEdit={props.canEdit}
                lang={props.lang}
                handlerPanelInfo={props.handlerPanelInfo}
            />
        </table>
        <AauComponentTablesNavigation
            key='component-table-navigation'
            recordCount={datapart.length}
            recordTotalCount={props.data.length}
            pageIndex={pageIndexState}
            onPageChange={(itemKey, value) => {
                setPageIndexState(Number(value));
            }}
            displayRows={(displayRowsValue !== null ? displayRowsValue : localStorage.getItem('display_rows')) as number}
            displayRowsList={(props.displayRowsList !== null ? props.displayRowsList : localStorage.getItem('display_rows_list')) as number[]}
            onDisplayRowChange={value => {
                setDisplayRowsValue(Number(value));
            }}
            lang={props.lang}
        />
    </div>;
};
AauComponentTable.defaultProps = defaultProps;
