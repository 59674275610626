/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import axios from 'axios';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import format from 'string-format';
import Cookies from 'js-cookie';
import {casdoor} from "../../casdoor";

import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";
import {
    Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Menu,
    MenuList, MenuPopover, MenuTrigger, Tooltip, Toolbar, ToolbarButton, tokens, useId, useToastController, Toast,
    ToastTitle, Spinner, ToastBody
} from "@fluentui/react-components";


export interface AauMixinBtnGroupProps {
    btns: object;
    dataJson: object;
    refreshTable?: () => void;
    columnid: string;
    lang: AauToolsLang;
    optionalMode?: boolean;
    maxButton?: number;
}

const defaultProps = {
    optionalMode: true,
    maxButton: 15
} as AauMixinBtnGroupProps;

export const AauMixinBtnGroup: FC<AauMixinBtnGroupProps> = props => {
    const btnsOptional = [];
    const btnsMandatory = [];

    const {dispatchToast, updateToast} = useToastController('askanduse_toaster');

    Object.keys(props.btns).forEach((btnKey, key) => {
        const btn = props.btns[btnKey];

        const optional = btn['optional'];
        const c = btn['component'];

        const btnActionName:string = btnKey;
        const confirm:boolean = c.hasOwnProperty('confirm') ? c.confirm : false;
        const displayRankField = c.hasOwnProperty('rank_record_field') ? c.rank_record_field : {};
        const displayRankFilters = c.hasOwnProperty('rank_filters') ? c.rank_filters : {};
        const displayState = c.hasOwnProperty('displayState') ? c.displayState : null;
        const displayDeployState = c.hasOwnProperty('displayDeployState') ? c.displayDeployState : null;
        const displayField = c.hasOwnProperty('displayField') ? c.displayField : null;
        const displayFieldValue = c.hasOwnProperty('displayFieldValue') ? c.displayFieldValue : [];
        const formatStr = c.hasOwnProperty('formatStr') ? c.formatStr : '#';

        let changeDesignBool = false;
            if ( c.hasOwnProperty('change_design') && props.dataJson['state'] === true ) {
            if ( c.hasOwnProperty('change_design') && props.dataJson['generatorlastactions'] !== null ) {
                let la = props.dataJson['generatorlastactions'].split(',')
                if ( (la.length >= 2 && (c.change_design.action.includes(la[0]) && la[1] !== btnKey)) || c.change_design.action.includes(la[1])  ){
                    changeDesignBool = true;
                }
            }
        }

        const module = window.location.href.split('/').reverse()[0];

        let display = true;
        if (displayState !== null && props.dataJson.hasOwnProperty('state') && !displayState.includes(props.dataJson['state'])) {
            display = false;
        }

        if (display !== false && displayDeployState !== null && props.dataJson.hasOwnProperty('deployState') && !displayDeployState.includes(props.dataJson['deployState'])) {
            display = false;
        }

        if (display !== false && displayField !== null && props.dataJson.hasOwnProperty(displayField) && !displayFieldValue.includes(props.dataJson[displayField])) {
            display = false;
        }

        // console.log('-----------');
        // console.log("Name", btnKey);
        // console.log("opts", c);
        // console.log("display", display);
        // console.log("displayRankField", displayRankField, "Has Field", props.dataJson.hasOwnProperty(displayRankField), "GET Value", props.dataJson[displayRankField]);
        // console.log("displayFrankFilters", displayRankFilters.hasOwnProperty(props.dataJson[displayRankField]));
        // console.log("displayState", displayState, "Has Field", props.dataJson.hasOwnProperty('state'), "GET Value", props.dataJson['state']);
        // console.log("displayDeployState", displayDeployState, "Has Field", props.dataJson.hasOwnProperty('deployState'), "GET Value", props.dataJson['deployState']);
        // console.log('-----------');

        if (display !== false && displayRankField !== null
            && props.dataJson.hasOwnProperty(displayRankField)
            && ((
                    displayRankFilters.hasOwnProperty(props.dataJson[displayRankField])
                    && displayRankFilters[props.dataJson[displayRankField]] === false
                ) || !displayRankFilters.hasOwnProperty(props.dataJson[displayRankField]))
        ) {
            display = false;
        }

        let ret = null;
        if ( display === true ) {
            ret = {
                name: btn['name'],
                label: btn['label'],
                i18n: btn['i18n'],
                icon: c['icon'],
                confirm: confirm,
                as: btn['mixin'] === 'btn_link_action_id' ? 'button' : 'a',
                target: btn['mixin'] === 'btn_link_with_url' ? '_blank' : null,
                toastId: `${btnActionName}_${props.dataJson[props.columnid]}`,
                link: btn['mixin'] === 'btn_link_with_url' ? format(formatStr, props.dataJson) : `/${module}/${btnActionName}/${props.dataJson[props.columnid]}`,
                appearance: changeDesignBool
            }

            if (props.optionalMode && optional) {
                btnsOptional.push(ret);
            } else {
                btnsMandatory.push(ret);
            }
        }
    });

    return <Toolbar key={`toolbar_${props.dataJson[props.columnid]}`} className={`width-px-${props.maxButton*32}-max float-right`} size={'small'} aria-label="Datatable Row Toolbar">
        {
            btnsMandatory.map(btn => {
                let ret;

                if ( btn['confirm'] === true ) {
                    ret = <Dialog modalType={`alert`} key={`button_confirm_${btn['name']}_${props.dataJson[props.columnid]}`}>
                        <DialogTrigger disableButtonEnhancement>
                            <Tooltip content={btn['label']} relationship="description">
                                <ToolbarButton
                                    key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                                    icon={<AauMixinIcon icon={btn['icon']}  />}
                                    appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                                    as={'button'}
                                />
                            </Tooltip>
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle
                                    action={
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<AauMixinIcon icon={`Dismiss24Regular`} />}
                                            />
                                        </DialogTrigger>
                                    }
                                >
                                    {window.generator.SITENAME}
                                </DialogTitle>
                                <DialogContent>
                                    {`${btn['i18n']['dialog']} de ${props.dataJson.hasOwnProperty('name') ? props.dataJson['name'] : ''} (${props.dataJson[props.columnid]})`}
                                    <br/>
                                    <br/>
                                    {props.lang.getText('dialog_confirm_text')}
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary">{props.lang.getBtn('cancel')}</Button>
                                    </DialogTrigger>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="primary" onClick={() => {
                                            dispatchToast(
                                                <Toast appearance="inverted">
                                                    <ToastTitle media={<Spinner size="tiny" />}>
                                                        {btn["i18n"]["progress"]} ({props.dataJson["popup_item_desc"]})
                                                    </ToastTitle>
                                                </Toast>,
                                        {
                                                    toastId: btn['toastId'],
                                                    timeout: -1
                                                }
                                            );
                                            axios.get(
                                                `/rest${btn['link']}`,
                                                {
                                                    baseURL: `${window.generator.BACKURL}`,
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                    }
                                                }
                                            ).then(res => {
                                                updateToast({
                                                    content: <Toast appearance="inverted">
                                                        <ToastTitle>
                                                            {btn["i18n"][res.data.success === true ? "success" : "error"]} ({props.dataJson["popup_item_desc"]})
                                                        </ToastTitle>
                                                        <ToastBody>
                                                            {res.data.action_return}
                                                        </ToastBody>
                                                    </Toast>,
                                                    toastId: btn['toastId'],
                                                    intent: res.data.success === true ? "success" : "error",
                                                    timeout: 3000
                                                });
                                                props.refreshTable();
                                            }).catch(err => {
                                                const unauthorizedError = 401;
                                                const notFoundError = 404;
                                                if ( err.response.status === unauthorizedError ) {
                                                    Cookies.remove('JWT');
                                                    Cookies.remove('user');
                                                    Cookies.remove('rank');
                                                    Cookies.remove('displayname');
                                                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                                                } else if ( err.response.status === notFoundError ) {
                                                    window.location.href = `/notfound`;
                                                } else {
                                                    updateToast({
                                                        content: <Toast appearance="inverted">
                                                            <ToastTitle>{props.lang.getError(err.response.data['code']).replace('%s', '')}</ToastTitle>
                                                        </Toast>,
                                                        toastId: btn['toastId'],
                                                        intent: "error",
                                                        timeout: 3000
                                                    });
                                                }
                                            });
                                        }}>
                                            {props.lang.getBtn('confirm')}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>;
                } else if ( btn['as'] === 'a' ) {
                    ret = <Tooltip key={`${btn['name']}_${props.dataJson[props.columnid]}_tooltip`} content={btn['label']} relationship="description">
                        <ToolbarButton
                            key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                            icon={<AauMixinIcon icon={btn['icon']} />}
                            as={btn['as']}
                            appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                            href={btn['link']}
                            target={btn['target']}
                        />
                    </Tooltip>;
                } else {
                    ret = <Tooltip key={`${btn['name']}_${props.dataJson[props.columnid]}_tooltip`} content={btn['label']} relationship="description">
                        <ToolbarButton
                            key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                            icon={<AauMixinIcon icon={btn['icon']} />}
                            as={btn['as']}
                            appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                            onClick={() => {
                                dispatchToast(
                                    <Toast appearance="inverted">
                                        <ToastTitle media={<Spinner size="tiny" />}>
                                            {btn["i18n"]["progress"]} ({props.dataJson["popup_item_desc"]})
                                        </ToastTitle>
                                    </Toast>,
                            {
                                        toastId: btn['toastId'],
                                        timeout: -1
                                    }
                                );
                                axios.get(
                                    `/rest${btn['link']}`,
                                    {
                                        baseURL: `${window.generator.BACKURL}`,
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                        }
                                    }
                                ).then(res => {
                                    updateToast({
                                        content: <Toast appearance="inverted">
                                            <ToastTitle>
                                                {btn["i18n"][res.data.success === true ? "success" : "error"]} ({props.dataJson["popup_item_desc"]})
                                            </ToastTitle>
                                            <ToastBody>
                                                {res.data.action_return}
                                            </ToastBody>
                                        </Toast>,
                                        toastId: btn['toastId'],
                                        intent: res.data.success === true ? "success" : "error",
                                        timeout: 3000
                                    });
                                    props.refreshTable();
                                }).catch(err => {
                                    const unauthorizedError = 401;
                                    if (err.response.data['code'] === unauthorizedError) {
                                        Cookies.remove('JWT');
                                        Cookies.remove('user');
                                        Cookies.remove('rank');
                                        Cookies.remove('displayname');
                                        window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                                    } else {
                                        updateToast({
                                            content: <Toast appearance="inverted">
                                                <ToastTitle>{props.lang.getError(err.response.data['code']).replace('%s', '')}</ToastTitle>
                                            </Toast>,
                                            toastId: btn['toastId'],
                                            intent: "error",
                                            timeout: 3000
                                        });
                                    }
                                });
                            }}
                            target={`_blank`}
                        />
                    </Tooltip>;
                }

                return ret;
            })
        }
        {
            btnsOptional.length > 0 ? <Menu key={`menu_showmore_${props.dataJson[props.columnid]}`}>
                <MenuTrigger disableButtonEnhancement>
                    <Tooltip content={props.lang.getBtn('showmoreaction')} relationship="description">
                        <ToolbarButton
                            key={`btn_showmore_${props.dataJson[props.columnid]}`}
                            icon={<AauMixinIcon icon={`MoreHorizontal24Filled`} />}
                        />
                    </Tooltip>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList className={'pt-6 pb-6 pr-3 pl-3'}>
                        {
                            btnsOptional.map(btn => {
                                let ret;

                                if ( btn['confirm'] === true ) {
                                    ret = <Dialog modalType={`alert`} key={`button_confirm_${btn['name']}_${props.dataJson[props.columnid]}`}>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button
                                                appearance={`subtle`}
                                                aria-labelledby={btn['label']}
                                                icon={<AauMixinIcon icon={btn['icon']} />}
                                                style={{alignItems: 'left', justifyContent: 'left', paddingLeft: 0, paddingRight: 0}}
                                            >
                                                {btn['label']}
                                            </Button>
                                        </DialogTrigger>
                                        <DialogSurface>
                                            <DialogBody>
                                                <DialogTitle
                                                    action={
                                                        <DialogTrigger action="close">
                                                            <Button
                                                                appearance="subtle"
                                                                aria-label="close"
                                                                icon={<AauMixinIcon icon={`Dismiss24Regular`} />}
                                                            />
                                                        </DialogTrigger>
                                                    }
                                                >
                                                    {window.generator.SITENAME}
                                                </DialogTitle>
                                                <DialogContent>
                                                    {`${btn['i18n']['dialog']} de ${props.dataJson.hasOwnProperty('name') ? props.dataJson['name'] : ''} (${props.dataJson[props.columnid]})`}
                                                    <br/>
                                                    <br/>
                                                    {props.lang.getText('dialog_confirm_text')}
                                                </DialogContent>
                                                <DialogActions>
                                                    <DialogTrigger disableButtonEnhancement>
                                                        <Button appearance="secondary">{props.lang.getBtn('cancel')}</Button>
                                                    </DialogTrigger>
                                                    <DialogTrigger disableButtonEnhancement>
                                                        <Button appearance="primary" onClick={() => {
                                                            dispatchToast(
                                                                <Toast appearance="inverted">
                                                                    <ToastTitle media={<Spinner size="tiny" />}>
                                                                        {btn["i18n"]["progress"]} ({props.dataJson["popup_item_desc"]})
                                                                    </ToastTitle>
                                                                </Toast>,
                                                        {
                                                                    toastId: btn['toastId'],
                                                                    timeout: -1
                                                                }
                                                            );
                                                            axios.get(
                                                                `/rest${btn['link']}`,
                                                                {
                                                                    baseURL: `${window.generator.BACKURL}`,
                                                                    headers: {
                                                                        'Content-Type': 'application/json',
                                                                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                                    }
                                                                }
                                                            ).then(res => {
                                                                updateToast({
                                                                    content: <Toast appearance="inverted">
                                                                        <ToastTitle>
                                                                            {btn["i18n"][res.data.success === true ? "success" : "error"]} ({props.dataJson["popup_item_desc"]})
                                                                        </ToastTitle>
                                                                        <ToastBody>
                                                                            {res.data.action_return}
                                                                        </ToastBody>
                                                                    </Toast>,
                                                                    toastId: btn['toastId'],
                                                                    intent: res.data.success === true ? "success" : "error",
                                                                    timeout: 3000
                                                                });
                                                                props.refreshTable();
                                                            }).catch(err => {
                                                                const unauthorizedError = 401;
                                                                if (err.response.data['code'] === unauthorizedError) {
                                                                    Cookies.remove('JWT');
                                                                    Cookies.remove('user');
                                                                    Cookies.remove('rank');
                                                                    Cookies.remove('displayname');
                                                                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                                                                } else {
                                                                    updateToast({
                                                                        content: <Toast appearance="inverted">
                                                                            <ToastTitle>{props.lang.getError(err.response.data['code']).replace('%s', '')}</ToastTitle>
                                                                        </Toast>,
                                                                        toastId: btn['toastId'],
                                                                        intent: "error",
                                                                        timeout: 3000
                                                                    });
                                                                }
                                                            });
                                                        }}>
                                                            {props.lang.getBtn('confirm')}
                                                        </Button>
                                                    </DialogTrigger>
                                                </DialogActions>
                                            </DialogBody>
                                        </DialogSurface>
                                    </Dialog>;
                                } else if ( btn['as'] === 'a' ) {
                                    ret = <Tooltip key={`${btn['name']}_${props.dataJson[props.columnid]}_tooltip`} content={btn['label']} relationship="description">
                                        <ToolbarButton
                                            key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                                            icon={<AauMixinIcon icon={btn['icon']} />}
                                            as={btn['as']}
                                            appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                                            href={btn['link']}
                                            target={btn['target']}
                                        >
                                            {btn['label']}
                                        </ToolbarButton>
                                    </Tooltip>;
                                } else {
                                    ret = <Tooltip key={`${btn['name']}_${props.dataJson[props.columnid]}_tooltip`} content={btn['label']} relationship="description">
                                        <ToolbarButton
                                            key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                                            icon={<AauMixinIcon icon={btn['icon']}/>}
                                            as={btn['as']}
                                            appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                                            onClick={() => {
                                                dispatchToast(
                                                    <Toast appearance="inverted">
                                                        <ToastTitle media={<Spinner size="tiny" />}>
                                                            {btn["i18n"]["progress"]} ({props.dataJson["popup_item_desc"]})
                                                        </ToastTitle>
                                                    </Toast>,
                                            {
                                                        toastId: btn['toastId'],
                                                        timeout: -1
                                                    }
                                                );
                                                axios.get(
                                                    `/rest${btn['link']}`,
                                                    {
                                                        baseURL: `${window.generator.BACKURL}`,
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                        }
                                                    }
                                                ).then(res => {
                                                    updateToast({
                                                        content: <Toast appearance="inverted">
                                                            <ToastTitle>
                                                                {btn["i18n"][res.data.success === true ? "success" : "error"]} ({props.dataJson["popup_item_desc"]})
                                                            </ToastTitle>
                                                            <ToastBody>
                                                                {res.data.action_return}
                                                            </ToastBody>
                                                        </Toast>,
                                                        toastId: btn['toastId'],
                                                        intent: res.data.success === true ? "success" : "error",
                                                        timeout: 3000
                                                    });
                                                    props.refreshTable();
                                                }).catch(err => {
                                                    const unauthorizedError = 401;
                                                    if (err.response.data['code'] === unauthorizedError) {
                                                        Cookies.remove('JWT');
                                                        Cookies.remove('user');
                                                        Cookies.remove('rank');
                                                        Cookies.remove('displayname');
                                                        window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                                                    } else {
                                                        updateToast({
                                                            content: <Toast appearance="inverted">
                                                                <ToastTitle>{props.lang.getError(err.response.data['code']).replace('%s', '')}</ToastTitle>
                                                            </Toast>,
                                                            toastId: btn['toastId'],
                                                            intent: "error",
                                                            timeout: 3000
                                                        });
                                                    }
                                                });
                                            }}
                                            target={`_blank`}
                                        >
                                            {btn['label']}
                                        </ToolbarButton>
                                    </Tooltip>;
                                }
                                return ret;
                            })
                        }
                    </MenuList>
                </MenuPopover>
            </Menu> : null
        }
    </Toolbar>
};
AauMixinBtnGroup.defaultProps = defaultProps;
