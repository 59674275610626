/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//TODO (vincent.candeau): Manage List key item

import React, {useState, FC} from 'react';

import {Combobox, Option} from "@fluentui/react-components";
import DualListBox from 'react-dual-listbox';

import './react-dual-listbox.scss';
import {Button} from "@fluentui/react-components";
import {PaddingLeft24Filled, ChevronLeft24Filled, ChevronRight24Filled, PaddingRight24Filled, PaddingTop24Filled, ChevronUp24Filled, ChevronDown24Filled, PaddingDown24Filled} from "@fluentui/react-icons";
import {AauFormField} from "../aauFormField/aauFormField";
import {tokens} from "@fluentui/react-theme";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";


export interface AauFormSelectProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value: string;
    parentFunction?: any;
    multiple?: boolean;
    enums?: object;
    required?: boolean | null;
    dualmode?: boolean;
    hint?: string;
}

const defaultProps = {
    readonly: false,
    value: '',
    multiple: false,
    enums: {},
    parentFunction: null,
    required: false,
    dualmode: false,
    hint: null
} as AauFormSelectProps;

export const AauFormSelect: FC<AauFormSelectProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (props.required === true && (validateValue.length < 1 || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue.join(','), isValid);
            }

            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': props.value.split(',')
    });

    if (state['isValid'] === null && props.readonly === false ) {
        validateField(props.value === '' ? [] : props.value.split(','));
    }

    const select_options_invert = []
    Object.entries(props.enums).forEach(([key, value]) => { select_options_invert[value as string] = key; });
    const [matchingOptions, setMatchingOptions] = React.useState(select_options_invert);
    // const [value, setValue] = React.useState("");

    let select;
    if ( props.dualmode === true && props.multiple === true) {
        const options = [];

        let selectedValuesText = [];

        Object.keys(props.enums).sort(
            (a,b) => props.enums[a].toString().localeCompare(props.enums[b].toString())
        ).forEach(key => {
            options.push({
                value: `${key}`,
                label: `${props.enums[key]}`
            });
        });

        state['content'].forEach(key => {
            if ( props.enums.hasOwnProperty(key) ) {
                selectedValuesText.push(key);
            }
        })

        select = <DualListBox
            canFilter
            preserveSelectOrder
            showOrderButtons
            icons={{
                moveLeft: <Button
                    icon={<ChevronLeft24Filled />}>
                </Button>,
                moveAllLeft: <Button
                    icon={<PaddingLeft24Filled />}>
                </Button>,
                moveRight: <Button
                    icon={<ChevronRight24Filled />}>
                </Button>,
                moveAllRight: <Button
                    icon={<PaddingRight24Filled />}>
                </Button>,
                moveDown: <Button
                    icon={<ChevronDown24Filled />}>
                </Button>,
                moveUp: <Button
                    icon={<ChevronUp24Filled />}>
                </Button>,
                moveTop: <Button
                    icon={<PaddingTop24Filled />}>
                </Button>,
                moveBottom: <Button
                    icon={<PaddingDown24Filled />}>
                </Button>,
            }}
            filterCallback={(option, filterInput) => {
                if (filterInput === '') {
                    return true;
                }

                return (new RegExp(filterInput, 'i')).test(option.label);
            }}
            filterPlaceholder="Filter..."
            options={options}
            selected={selectedValuesText}
            onChange={(selected) => validateField(selected)}
        />;
    } else {
        select = <Combobox
            key={`${props.itemKey}-ctrl`}
            aria-labelledby={props.name}
            appearance={'underline'}
            inlinePopup={false}
            size={`medium`}
            //input={{style: {width: cssWidth - (8 * 2) - 24}}}
            listbox={{style: {width: 'fit'}}}
            multiselect={props.multiple}
            expandIcon={<AauMixinIcon icon='ChevronDown24Filled'/>}
            freeform={false}
            //defaultValue={props.multiple ? state['content'].join(',') : props.enums[state['content'].join(',')]}
            value={props.multiple ? state['content'].join(',') : props.enums[state['content'].join(',')]}
            selectedOptions={state['content']}
            // onBlur={onBlur}
            // onChange={onChange}
            // onFocus={onFocus}
            // onOptionSelect={onSelect}
            // onChange={(ev) => {
            //     const value = ev.target.value.trim();
            //     const matches = value !== '' ? Object.keys(select_options_invert).filter(option => option.toLowerCase().indexOf(value.toLowerCase()) === 0) : Object.keys(select_options_invert);
            //
            //     const select_options_invert_tmp = []
            //     matches.forEach(value => { select_options_invert_tmp[value as string] = select_options_invert[value]; });
            //
            //     setMatchingOptions(select_options_invert_tmp);
            // }}
            onOptionSelect={(event, data) => {
                validateField(data.selectedOptions.length >= 1 ? data.selectedOptions : null);
            }}
        >
            {
                    Object.keys(matchingOptions).sort(function (first, second) {
                        let ret = 0;

                        if (first !== '' && second !== '' && first !== null && second !== null) {
                            if (first.toString().toLowerCase() < second.toString().toLowerCase()) {
                                ret = -1;
                            } else if (first.toString().toLowerCase() > second.toString().toLowerCase()) {
                                ret = 1;
                            } else {
                                //NOSONAR
                            }
                        }

                        return ret;
                    }).map(key => (
                        <Option
                            id={`filter_${props.name}_${matchingOptions[key].replace(new RegExp("[\ \/\?\*\&\@\|\.]", "g"), '_').replace('')}`}
                            key={`filter_${props.name}_${matchingOptions[key].replace(new RegExp("[\ \/\?\*\&\@\|\.]", "g"), '_')}`}
                            text={matchingOptions[key]}
                        >
                            {key}
                        </Option>
                    ))
            }
        </Combobox>
    }

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        content={ props.readonly === true ? <div>{props.enums[state['content']]}</div> : select}
        hint={props.hint}
    />;
};
AauFormSelect.defaultProps = defaultProps;
