/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauComponentTableColumnProps} from './tableHeadColumn.d';
import {AauComponentTableBodyRow} from './tableBodyRow';
import {tokens} from "@fluentui/react-theme";


export interface AauComponentTableBodyProps {
    columns: AauComponentTableColumnProps[];
    data: object[];
    refreshTable?: () => void;
    columnid: string;
    canEdit: boolean;
    canView: boolean;
    canSaveAndAction: string|null;
    saveAndActionLabel?: string;
    btnSave?: object;
    viewAction?: string;
    lang: AauToolsLang;
    handlerPanelInfo?: (url:string) => void;
}

const defaultProps = {
    canEdit: false,
    canView: false,
    canSaveAndAction: null,
    viewAction: 'view',
    btnSave: {}
} as AauComponentTableBodyProps;

export const AauComponentTableBody: FC<AauComponentTableBodyProps> = props => {
    let colSpan = 0;
    if ( props.data.length === 0 ) {
        Object.keys(props.columns).forEach(column => {
            if (props.columns[column]['state'] === true) {
                colSpan += 1;
            }
        });
    }

    return <tbody>
        {
            props.data.length !== 0 ?
            Object.keys(props.data).map(
                id => <AauComponentTableBodyRow
                    key={`data-${id}`}
                    rowKey={parseInt(id, 10)}
                    columns={props.columns}
                    data={props.data[id]}
                    refreshTable={props.refreshTable}
                    columnid={props.columnid}
                    canView={props.canView}
                    canEdit={props.canEdit}
                    canSaveAndAction={props.canSaveAndAction}
                    saveAndActionLabel={props.saveAndActionLabel}
                    btnSave={props.btnSave}
                    viewAction={props.viewAction}
                    lang={props.lang}
                    handlerPanelInfo={props.handlerPanelInfo}
                />
            ) :
            <tr key={`data-notfound`} style={{
                backgroundColor: tokens.colorNeutralBackground1,
                color: tokens.colorNeutralForeground4
            }}>
                <td colSpan={colSpan} className={`p-2 text-bold text-center`} style={{
                    backgroundColor: tokens.colorNeutralBackground2Hover,
                    color: tokens.colorNeutralForeground4
                }}>
                    NOT FOUND
                </td>
            </tr>
        }
    </tbody>;
};
AauComponentTableBody.defaultProps = defaultProps;
