import initCasdoorSdk from 'casdoor-js-sdk';

const sdkConfig = {
  serverUrl: window.generator.CASDOOR_URL,
  clientId: window.generator.CASDOOR_CLIENTID,
  organizationName: window.generator.CASDOOR_ORGANIZATION,
  appName: window.generator.CASDOOR_APPNAME,
  redirectPath: "/auth/casdoor/callback",
  signinPath: "/auth/casdoor"
};

export const casdoor = new initCasdoorSdk(sdkConfig);