/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Switch} from '@fluentui/react-components';


export interface AauMixinSwitchBoolProps {
    value:  any;
    label?: string;
}

const defaultProps = {
    label: null
} as AauMixinSwitchBoolProps;

export const AauMixinSwitchBool: FC<AauMixinSwitchBoolProps> = props => {
    const {value} = props;

    return <div
        style={{
            position: 'relative'
        }}
    >
        <Switch
            style={{
                textAlign: 'left',
                zIndex: 10
            }}
            checked={value === 1 || value === true ? true : false}
            label={props.label}
        />
        <div style={{
            position: 'absolute',
            display: 'block',
            width: '100%',
            height: '36px',
            top: '0px',
            zIndex: 20
        }}></div>
    </div>;
};
