/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import './scss/typography.scss';
import './scss/sizing.scss';

import React, {useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import {AuthCallback} from "casdoor-react-sdk";
import {casdoor} from "./casdoor";
import Cookies from 'js-cookie';

import AauToolsLang from "./Components/aauToolsLang/aauToolsLang";
import axios from 'axios';

import {
    Toaster,
    MenuList,
    MenuTrigger,
    MenuPopover,
    Menu,
    MenuButton,
    ToggleButton,
    Button,
    Tooltip,
    Input
} from "@fluentui/react-components";
import {tokens} from "@fluentui/react-theme";

import {AauMixinLoader} from './Components/aauMixinLoader/aauMixinLoader';

import {AauComponentMenuHorizontal} from './Components/aauComponentMenuHorizontal/aauComponentMenuHorizontal';
import {AauPageForbidden} from "./Components/aauPageForbidden/aauPageForbidden";

import {AauPageList} from './Components/aauPageList/aauPageList';
import {AauPageForm} from './Components/aauPageForm/aauPageForm';
import {AauPageRecordHistory} from './Components/aauPageRecordHistory/aauPageRecordHistory';
import {AauPageRecordHistoryDetail} from "./Components/aauPageRecordHistoryDetail/aauPageRecordHistoryDetail";
import {AauPageSearch} from "./Components/aauPageSearch/aauPageSearch";
import {AauPageNotFound} from "./Components/aauPageNotFound/aauPageNotFound";
import {AauPageDashboard} from "./Components/aauPageDashboard/aauPageDashboard";
import {AauPageTreeview} from "./Components/aauPageTreeview/aauPageTreeview";
import {AauMixinIcon} from "./Components/aauMixinIcon/aauMixinIcon";
import {AauMixinAvatar} from "./Components/aauMixinAvatar/aauMixinAvatar";

//import {AauPageLogin} from './Components/aauPageLogin/aauPageLogin';
//import {AauPageLog} from '../old/aauPageLog/aauPageLog';
//import {AauPageDiffFile} from '../old/aauPageDiffFile/aauPageDiffFile';
//import {AauPageViewDetailList} from '../old/aauPageViewDetailList/aauPageViewDetailList';
//import {AauPageYamlView} from "../old/aauPageYamlView/aauPageYamlView";

// import {} from "@fluentui/react-components";
//import qs from "qs";

type ResponseLogin = {
    displayname?: string;
    login?: string;
    msg?: string;
    rank?: string;
    success?: boolean
    token?: string
};

declare global {
    interface Window {
        generator?: any;
    }
}

function App() {
    let qs = require('qs'); // SONAR
    let menuContent = null;
    let content;
    //let filterContent = null;

    //const toasterId = useId("toaster");

    const retrieveMenu = async () => {
        await axios.get(
            `/rest/menu_with_persona`,
            {
                baseURL: `${window.generator.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(typeof(Cookies.get('JWT')) !== 'undefined' ? Cookies.get('JWT') : null)
                }
            }
        ).then(res => {
            setDmenu(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const [dmenu, setDmenu] = useState(null);
    const [data, setData] = useState(null);
    const [search, setSearch] = useState(null);

    const lang = new AauToolsLang();

    lang.load();

    let profil = {};
    if ( lang.isLoad() ){
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        const isLogged = typeof(Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
        profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

        if ( isLogged && params.hasOwnProperty('redirect') ) {
            window.location.href = `${params.redirect}`;
        } else if ( isLogged && window.location.pathname === '/login' ) {
            window.location.href = `/`;
        }

        if ( isLogged ) {
            if ( dmenu !== null && dmenu !== false && dmenu !== "waiting" ) {
                let menuObject = JSON.parse(JSON.stringify(dmenu['menu']));
                menuContent = <div
                    className={`width-100 pos-fixed z-412 top-px-0`}
                    style={{backgroundColor: tokens.colorNeutralBackground1}}
                >
                    <div className={`height-px-48`} style={{backgroundColor: tokens.colorNeutralShadowKeyDarker}}>
                        <div className='float-left width-px-900-min'>
                            <AauComponentMenuHorizontal
                                data={menuObject}
                            />
                        </div>
                        <div className='float-right width-px-400-min'>
                            <Input
                                id={`global_search`}
                                key={`global_search`}
                                name={`global_search`}
                                aria-labelledby={`global_search`}
                                appearance={`underline`}
                                placeholder={lang.getText('search')}
                                value={data !== null ? data : ''}
                                onChange={(e, data) => {
                                    setData(data['value']);
                                }}
                                onKeyPress={(e) => {
                                    if ( ['Enter', 'NumpadEnter'].includes(e.code) ) {
                                        setSearch(data);
                                    }
                                }}
                                contentAfter={<AauMixinIcon icon='Search24Filled'/>}
                                type={`text`}
                                size={`medium`}
                                className={`float-left width-px-175 mr-4 mt-2`}
                            />
                            <Menu
                                openOnHover={true}
                                closeOnScroll={true}
                            >
                                <MenuTrigger disableButtonEnhancement>
                                    <MenuButton size={`small`} appearance={`subtle`}>
                                        <AauMixinAvatar
                                            as={'persona'}
                                            className={`mt-1`}
                                            displayName={dmenu['persona']['displayName']}
                                            secondaryText={dmenu['persona']['title']}
                                        />
                                    </MenuButton>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <ToggleButton
                                            checked={(profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false) === false}
                                            appearance={`subtle`}
                                            icon={<AauMixinIcon icon={(profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false) ? 'WeatherMoon24Regular' : 'WeatherSunny24Regular'}/>}
                                            onClick={() => {
                                                profil['darkMode'] = (profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false);
                                                localStorage.setItem(Cookies.get('user'), JSON.stringify(profil));
                                                window.location.reload();
                                            }}
                                        >
                                            {(profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false) ? lang.getText('theme_dark', null,'Dark Theme') : lang.getText('theme_light', null, 'Light Theme')}
                                        </ToggleButton>
                                        <Button
                                            as={`a`}
                                            target={`_blank`}
                                            appearance="subtle"
                                            style={{textDecoration: "none"}}
                                            href={`${window.generator.CASDOOR_URL}/account`}
                                        >
                                            {lang.getText('profile', 'btn', 'Profile Casdoor')}
                                        </Button>
                                        <Button
                                            appearance="subtle"
                                            style={{textDecoration: "none"}}
                                            onClick={() => {
                                                Cookies.remove('JWT');
                                                Cookies.remove('user');
                                                Cookies.remove('rank');
                                                Cookies.remove('displayname');
                                                sessionStorage.clear();
                                                window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login`;
                                            }}
                                        >
                                            {lang.getText('logout', 'btn', 'Logout')}
                                        </Button>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            {
                                window.generator.HELPDESKURL !== '' ? <Tooltip content={lang.getBtn('create_support_ticket')} relationship="description">
                                    <Button
                                        as={'a'}
                                        size={`large`}
                                        icon={<AauMixinIcon icon='Headset48Regular'/>}
                                        appearance={`subtle`}
                                        className={`position-relative right-5`}
                                        target={`_blank`}
                                        href={window.generator.HELPDESKURL}
                                    />
                                </Tooltip> : null
                            }
                        </div>
                    </div>
                </div>;
            } else if ( dmenu !== false ) {
                setDmenu(false);
                retrieveMenu();
            } else {
                // NO// SONAR
            }
        } else if ( ![
                '/auth/login',
                '/auth/casdoor/callback',
                '/auth/casdoor/signin',
                '/auth/logout'
            ].includes(window.location.pathname)
        ) {
            window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
        } else if ( ![
                '/auth/login',
                '/auth/casdoor/callback',
                '/auth/casdoor/signin',
            ].includes(window.location.pathname)
        ) {
            window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login`;
        } else {
            // NO// SONAR
        }

        if ( search !== null ) {
            window.location.href = `/search/${search}`;
        } else if ( data === false ) {
            content = <div className={`width-100 height-vh-100-min`}>
                <AauMixinLoader />
            </div>;
        } else {
            const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            content = (isLogged === false || (isLogged === true && dmenu !== false))
            ? <Routes>
                <Route path='/forbidden' element={<AauPageForbidden msg={params.hasOwnProperty("msg") ? params.msg : 'unknow'}/>} />
                <Route path='/notfound' element={<AauPageNotFound />} />
                <Route path='/' element={<AauPageDashboard lang={lang} />} />
                {/*<Route path='/auth/login' element={<AauPageLogin casdoor={false} login_action={true} lang={lang}/>} />*/}
                <Route path='/auth/casdoor/callback' element={
                    <AuthCallback
                        sdk={casdoor}
                        serverUrl={window.generator.BACKURL}
                        saveTokenFromResponse={(res) => {
                            //update_account_token(res.token);
                        }}
                        isGetTokenSuccessful={(res) => {
                            let restoken = res as ResponseLogin;
                            let ret = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;

                            console.debug(res)

                            if ( restoken.success === true ) {
                                const user = restoken.login;

                                Cookies.set('JWT', restoken.token);
                                Cookies.set('user', user);
                                Cookies.set('rank', restoken.rank);
                                Cookies.set('displayname', restoken.displayname);

                                let profil = null;
                                if (localStorage.getItem(user) === null) {
                                    profil = {};
                                } else {
                                    profil = JSON.parse(localStorage.getItem(user));
                                }
                                if ( profil.hasOwnProperty('favorites') !== true  ) {
                                    profil['favorites'] = {};
                                }
                                if ( profil.hasOwnProperty('filters') !== true ) {
                                    profil['filters'] = {};
                                }
                                if ( profil.hasOwnProperty('darkMode') !== true ) {
                                    profil['darkMode'] = true;
                                }
                                localStorage.setItem(user, JSON.stringify(profil));

                                // var qs = require('qs'); //SONAR
                                // const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

                                ret = '/';
                                // if ( params.hasOwnProperty('redirect') ) {
                                //     ret = params.redirect;
                                // }

                               // window.location.href = ret;
                            } else {

                            }

                            window.location.href = ret;
                            return restoken.success;
                        }}
                    />
                } />
                <Route path='/search/:search' element={<AauPageSearch lang={lang}/>} />
                <Route path='/:module/history/:identifier' element={<AauPageRecordHistory lang={lang}/>} />
                <Route path='/:module/historydetail/:identifier' element={<AauPageRecordHistoryDetail lang={lang}/>} />
                <Route path='/:module/treeview' element={<AauPageTreeview lang={lang}/>} />
                <Route path='/:module/:action/:identifier' element={<AauPageForm lang={lang}/>} />
                <Route path='/:module' element={<AauPageList lang={lang}/>} />
            </Routes>
            : <AauMixinLoader />;
        }
    } else {
        content = <div className={`width-100 height-vh-100-min`}>
            <AauMixinLoader />
        </div>;
        setTimeout(() => window.location.reload(), 2000);
    }

    return <div className={`width-100 height-vh-100-min`} style={{backgroundColor: tokens.colorNeutralBackground1}}>
        <Router>
            {menuContent}
            <Toaster
                toasterId={'askanduse_toaster'}
                position='bottom'
                timeout={3000}
            />
            {content}
        </Router>
        <div className={"float-clear"} />
    </div>;
}

export default App;


