/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//TODO (vincent.candeau): Manage List key item

import React, {useState, FC} from 'react';

import {Combobox, Option} from "@fluentui/react-components";
import {AauFormField} from "../aauFormField/aauFormField";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";
import { AauMixinAvatar} from '../aauMixinAvatar/aauMixinAvatar';


export interface AauFormPersonaProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value: string;
    parentFunction?: any;
    enums?: object;
    required?: boolean | null;
    hint?: string;
    multiple?: boolean;
}

const defaultProps = {
    readonly: false,
    value: '',
    enums: {},
    parentFunction: null,
    required: false,
    hint: null,
    multiple: false
} as AauFormPersonaProps;

export const AauFormPersona: FC<AauFormPersonaProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (props.required === true && (validateValue.length < 1 || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue.join(','), isValid);
            }

            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': props.value.split(',')
    });

    if (state['isValid'] === null && props.readonly === false ) {
        validateField(props.value === '' ? [] : props.value.split(','));
    }

    const select_options_invert = []
    Object.entries(props.enums).forEach(([key, value]) => { select_options_invert[value as string] = key; });
    const [matchingOptions, setMatchingOptions] = React.useState(select_options_invert);

    const select = <Combobox
        key={`${props.itemKey}-ctrl`}
        aria-labelledby={props.name}
        appearance={'underline'}
        inlinePopup={false}
        size={`medium`}
        listbox={{style: {width: 'fit'}}}
        multiselect={false}
        expandIcon={<AauMixinIcon icon='ChevronDown24Filled'/>}
        freeform={false}
        value={props.multiple ? state['content'].join(',') : props.enums[state['content'].join(',')]}
        selectedOptions={state['content']}
        onOptionSelect={(event, data) => {
            validateField(data.selectedOptions.length >= 1 ? data.selectedOptions : null);
        }}
    >
        {
            Object.keys(matchingOptions).sort(function (first, second) {
                let ret = 0;

                if (first !== '' && second !== '' && first !== null && second !== null) {
                    if (first.toString().toLowerCase() < second.toString().toLowerCase()) {
                        ret = -1;
                    } else if (first.toString().toLowerCase() > second.toString().toLowerCase()) {
                        ret = 1;
                    } else {
                        //NOSONAR
                    }
                }

                return ret;
            }).map(key => (
                <Option
                    id={`filter_${props.name}_${matchingOptions[key].replace(new RegExp("[\ \/\?\*\&\@\|\.]", "g"), '_').replace('')}`}
                    key={`filter_${props.name}_${matchingOptions[key].replace(new RegExp("[\ \/\?\*\&\@\|\.]", "g"), '_')}`}
                    text={matchingOptions[key]}
                >
                    <AauMixinAvatar
                        as={`persona`}
                        displayName={key}
                    />
                </Option>
            ))
        }
    </Combobox>;

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}   
        content={ props.readonly === true ? <AauMixinAvatar 
            as={`persona`}  
            displayName={props.enums[state['content'][0]]}
        /> : select}
        hint={props.hint}
    />;
};
AauFormPersona.defaultProps = defaultProps;
