/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';
import axios from 'axios';
import Cookies from 'js-cookie';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {AauMixinFilters} from '../aauMixinFilters/aauMixinFilters';
import {AauPageRecordHistoryCard} from "./aauPageRecordHistoryCard";
import {AauMixinFabBar} from "../aauMixinFabBar/aauMixinFabBar";
import {tokens} from "@fluentui/react-theme";
import {AauMixinDiffFile} from "../aauMixinDiffFile/aauMixinDiffFile";
import {casdoor} from '../../casdoor';


export interface AauPageRecordHistoryProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageRecordHistoryProps;

export const AauPageRecordHistory: FC<AauPageRecordHistoryProps> = props => {
    const {lang} = props;
    const {module, identifier} = useParams();
    const [data, setData] = useState(null);

    const [filterAction, setFilterAction] = useState<string>('ALL');
    const [filterCreatedBy, setFilterCreatedBy] = useState<string>('ALLUSER');
    const [rowId, setRowId] = useState<number>(null);

    const history = useNavigate();

    const retrieveData = async () => {
        await axios.get(
            `/rest/${module}/history/${identifier}`,
            {
                baseURL: `${window.generator.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    const rollBackData = async rowdId => {
        await axios.get(
            `/rest/${module}/rollback/${rowdId}`,
            {
                baseURL: `${window.generator.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            // TODO(vincent.candeau): Need to be revamp
            if (res.data.success === true) {
                const msgToast = lang.getNotification('rollback', true);
                // TODO(vincent.candeau): Revamp when need
                //toast.success(msgToast.replace('%s', `${res.data.info.name} vers la version ${res.data.info.logid})`));
                history(`/${module}/view/${res.data.info.id}`);
            } else {
                const msgToast = lang.getNotification('rollback', false);
                // TODO(vincent.candeau): Revamp when need
                //toast.error(msgToast.replace('%s', `${res.data.info.name} vers la version ${res.data.info.logid})`));
            }
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    const optionsAction = [{
        key: `ALL`,
        text: `${lang.getAction('ALL')}`
    }];
    const optionsCreatedBy = [{
        key: `ALLUSER`,
        text: `${lang.getSelectOption('user-filter', 'ALLUSER')}`
    }];


    const dataArray = [];
    if ( data !== null && data !== false ) {
        const optionsActionArray = [];
        const optionsCreatedByArray = [];

        Object(data['info']).forEach(row => {
            if ((filterAction === 'ALL' || row['action'] === filterAction) && (filterCreatedBy === 'ALLUSER' || row['createdBy'] === filterCreatedBy)) {
                dataArray.push({
                    key: row['id'],
                    action: row['action'],
                    delta: row['delta'],
                    user: row['createdBy'],
                    ts: `${row['createdAt'].split('.')[0]}`
                });
            }

            if ( !optionsActionArray.includes(row['action']) ) {
                optionsActionArray.push(row['action']);
                optionsAction.push({
                    key: `${row['action']}`,
                    text: `${row['action']}`
                });
            }
            if ( !optionsCreatedByArray.includes(row['createdBy']) ) {
                optionsCreatedByArray.push(row['createdBy']);
                optionsCreatedBy.push({
                    key: `${row['createdBy']}`,
                    text: `${row['createdBy']}`
                });
            }
        })
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='mt-23 p-2 pl-2 pr-15' style={{backgroundColor: tokens.colorNeutralBackground1}}>
                {
                    data !== null && data !== false && rowId === null ? <AauMixinFilters
                        lang={lang}
                        className={'mb-5'}
                        filters={[
                            {component: 'select', name: 'action', 'width': 200, 'options': optionsAction, changeHandler: (option, value) => {
                                if (typeof (option) !== 'undefined' ) {
                                    setFilterAction(option.toString());
                                }
                            }, value: `${lang.getAction(filterAction)}`},
                            {component: 'select', name: 'by', 'width': 200, 'options': optionsCreatedBy, changeHandler: (option, value) => {
                                if (typeof (option) !== 'undefined' ) {
                                    setFilterCreatedBy(option.toString());
                                }
                            }, value: `${lang.getSelectOption('user-filter', filterCreatedBy)}`}
                        ]}
                    /> : null
                }
                {
                     data !== null && data !== false && rowId === null ? <ResponsiveMasonry
                        columnsCountBreakPoints={{600: 3, 1200: 4, 1800: 5, 2400:6, 3000:7}}
                    >
                        <Masonry gutter={20}>
                            {
                                dataArray.map((item: { key: string | number }) => (
                                    <AauPageRecordHistoryCard
                                        rowid={item['key'] as string}
                                        key={item['key']}
                                        user={item['user']}
                                        action={item['action']}
                                        delta={item['delta']}
                                        timestamp={item['ts']}
                                        lang={props.lang}
                                        viewClickHandler={()=> setRowId(item['key'] as number)}
                                        rollbackClickHandler={data !== null && data !== false && data['can_rollback'] === true ? () => rollBackData(item['key'] as number) : null}
                                    />
                                ))
                            }
                        </Masonry>
                    </ResponsiveMasonry> : null
                }
                {
                    data !== null && data !== false && rowId !== null ? <AauMixinDiffFile
                        oldValue={JSON.stringify(data['info'].filter(row => row.id === rowId)[0]['olditem'],null,4)}
                        newValue={JSON.stringify(data['info'].filter(row => row.id === rowId)[0]['newitem'],null,4)}
                        leftTitle={lang.getText('olditem')}
                        rightTitle={lang.getText('newitem')}
                    /> : null
                }
            </div>
            <AauMixinFabBar
                back={`/${module}`}
                lang={props.lang}
                urlBasePath={`${module}`}
                fabs={{
                    view: {
                        enabled: data !== null && data !== false && data['can_view'] && window.location.pathname.indexOf('/edit/') !== -1,
                        confirm: false,
                        label: props.lang.getText('view'),
                        component: {
                            confirm: false,
                            icon: 'EyeTracking24Filled',
                            url: `/view/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    }
                }}
            />
        </>
    );
};
AauPageRecordHistory.defaultProps = defaultProps;
