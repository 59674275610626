/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Avatar, Persona} from "@fluentui/react-components";


export interface AauMixinAvatarProps {
    as?: 'avatar' | 'persona';
    displayName?: string;
    className?: string;
    secondaryText?: string;
    shape?: 'circular' | 'square';
    onMouseOverHandler?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseOutHandler?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const defaultProps = {
    as: 'avatar',
    shape: 'square',
    displayName: 'Unknow User',
    className: 'mx-auto width-48 text-center mt-1 mb-1',
    secondaryText: null,
    onMouseOverHandler: null,
    onMouseOutHandler: null
} as AauMixinAvatarProps;

export const AauMixinAvatar: FC<AauMixinAvatarProps> = props => {
    return props.as === 'avatar'
        ? <Avatar
            size={32}
            name={props.displayName}
            color={`colorful`}
            shape={props.shape}
            className={props.className}
            image={{src: `${window.generator.ASSETSURL}/casdoor/avatar/${window.generator.CASDOOR_ORGANIZATION}/${props.displayName}.jpeg?t=${Date.now()}`}}
            onMouseOver={props.onMouseOverHandler}
            onMouseOut={props.onMouseOutHandler}
        /> : <Persona
            size={`medium`}
            name={props.displayName}
            className={props.className}
            avatar={{
                image: {
                    src:
                        `${window.generator.ASSETSURL}/casdoor/avatar/${window.generator.CASDOOR_ORGANIZATION}/${props.displayName}.jpeg?t=${Date.now()}`
                    },
                }
            }
            onMouseOver={props.onMouseOverHandler}
            onMouseOut={props.onMouseOutHandler}
            secondaryText={props.secondaryText !== null ? props.secondaryText : ''}
        />
};
AauMixinAvatar.defaultProps = defaultProps;
