/*
 * Generator Front is a the web front design to be on top of Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinLoader} from '../aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {tokens} from "@fluentui/react-theme";
import {AauMixinPageHeader} from "../aauMixinPageHeader/aauMixinPageHeader";
import {AauMixinFabBar} from "../aauMixinFabBar/aauMixinFabBar";
import {AauMixinTreeView} from "../aauMixinTreeView/aauMixinTreeView";
import {casdoor} from '../../casdoor';


export interface AauPageTreeviewProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageTreeviewProps;

export const AauPageTreeview: FC<AauPageTreeviewProps> = props => {
    const {module, action, identifier, admin} = useParams();
    const [data, setData] = useState(null);
    const [dataExtra, setDataExtra] = useState(true);

    const history = useNavigate();

    let content = <AauMixinLoader />;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${module}/treeview`,
            {
                baseURL: `${window.generator.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = window.generator.CASDOOR ? casdoor.getSigninUrl() : `/auth/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='mt-23 p-2 pl-2 pr-15' style={{backgroundColor: tokens.colorNeutralBackground1}}>
                {
                    data !== null && data !== false ? <AauMixinTreeView
                        data={data['info']}
                        closeAfterLevel={2}
                    /> : <AauMixinLoader />
                }
            </div>
            <AauMixinFabBar
                back={`/${module}`}
                lang={props.lang}
                urlBasePath={`${module}`}
            />
        </div>
    );
};
AauPageTreeview.defaultProps = defaultProps;
