/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {Tooltip} from '@fluentui/react-components';
import {Toolbar, ToolbarButton, Combobox, Option} from '@fluentui/react-components';
import {ArrowPrevious24Filled, IosArrowLtr24Filled, IosArrowRtl24Filled, ArrowNext24Filled} from "@fluentui/react-icons";
import {tokens} from "@fluentui/react-theme";
import {ChevronDown24Filled} from "@fluentui/react-icons/lib/fonts";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";

export interface AauComponentTablesNavigationProps {
    recordCount: number;
    recordTotalCount: number;
    pageIndex: number;
    onPageChange: any;
    displayRows: number;
    displayRowsList: number[];
    onDisplayRowChange: any;
    lang: AauToolsLang;
}

const defaultProps = {
} as AauComponentTablesNavigationProps;

export const AauComponentTablesNavigation: FC<AauComponentTablesNavigationProps> = props => {
    const buttonNavFirst = <Tooltip content={props.lang.getBtn('nav_first')} relationship="description" withArrow>
        <ToolbarButton
            icon={<ArrowPrevious24Filled />}
            className={`mt-2-minus m-1 float-right`}
            style={{color: tokens.colorBrandForeground1}}
            onClick={() => props.onPageChange('props.pageIndex', 1)}
        />
    </Tooltip>;
    const buttonNavPrevious = <Tooltip content={props.lang.getBtn('nav_previous')} relationship="description" withArrow>
        <ToolbarButton
            icon={<IosArrowLtr24Filled />}
            className={`mt-2-minus m-1 float-right`}
            style={{color: tokens.colorBrandForeground1}}
            onClick={() => props.onPageChange('props.pageIndex', props.pageIndex === 1 ? 1 : props.pageIndex-1)}
        />
    </Tooltip>;
    const buttonNavNext = <Tooltip content={props.lang.getBtn('nav_next')} relationship="description" withArrow>
        <ToolbarButton
            icon={<IosArrowRtl24Filled />}
            className={`mt-2-minus m-1 float-right`}
            style={{color: tokens.colorBrandForeground1}}
            onClick={() => props.onPageChange('props.pageIndex', props.pageIndex === Math.ceil(props.recordCount/props.displayRows) ? Math.ceil(props.recordCount/props.displayRows) : props.pageIndex+1)}
        />
    </Tooltip>;
    const buttonNavLast = <Tooltip content={props.lang.getBtn('nav_last')} relationship="description" withArrow>
        <ToolbarButton
            icon={<ArrowNext24Filled />}
            className={`mt-2-minus m-1 float-right`}
            style={{color: tokens.colorBrandForeground1}}
            onClick={() => props.onPageChange('props.pageIndex', Math.ceil(props.recordCount/props.displayRows))}
        />
    </Tooltip>;

    return <footer key='navigation' className='pos-relative d-block z-10'>
        <div key='navigation_stats_1' className={`d-block float-left mt-1 ml-2`}>
            Page {props.pageIndex} / {Math.ceil(props.recordCount/props.displayRows)} contenant
        </div>
        <Combobox
            id={`navigation_displayRows`}
            key={`navigation_displayRows`}
            appearance={'underline'}
            inlinePopup={true}
            size={'medium'}
            input={{style: {width: 70 - (8 * 2) - 24}}}
            listbox={{style: {width: 'fit'}}}
            aria-labelledby={'navigation_displayRows'}
            className={`float-left mt-1-minus ml-2`}
            expandIcon={<AauMixinIcon icon='ChevronDown24Filled'/>}
            style={{minWidth: '70px', 'width': '70px', 'maxWidth': '70px', 'color': tokens.colorBrandForeground1}}
            placeholder={props.displayRows.toString()}
            onOptionSelect={(event, data) => {
                props.onDisplayRowChange(data.optionValue);
            }}
        >
            {
                Object.keys(props.displayRowsList).map(key => (
                    <Option key={key} value={props.displayRowsList[key].toString()}>
                        {props.displayRowsList[key].toString()}
                    </Option>
                ))
            }
        </Combobox>
        <div key='navigation_stats_2' className={`d-block float-left mt-1`}>
            &eacute;lements sur un total de {props.recordCount}
            { props.recordCount !== props.recordTotalCount ? ` dont (${props.recordTotalCount-props.recordCount} filtrés)` : null}
        </div>
        {
            props.recordTotalCount > props.displayRows && <Toolbar size={'small'} aria-label="Default" className={`float-right mt-1`}>
                {props.recordCount !== 0 ? buttonNavFirst : null}
                {props.recordCount !== 0 && Math.ceil(props.recordCount/props.displayRows) >=2 ? buttonNavPrevious : null}
                {/*{props.recordCount !== 0 ? buttonNavP1 : null}*/}
                {/*{props.recordCount !== 0 && Math.ceil(props.recordCount/props.displayRows) >=2 ? buttonNavP2 : null}*/}
                {/*{props.recordCount !== 0 && Math.ceil(props.recordCount/props.displayRows) >=3 ? buttonNavP3 : null}*/}
                {props.recordCount !== 0 && Math.ceil(props.recordCount/props.displayRows) >=2 ? buttonNavNext : null}
                {props.recordCount !== 0 ? buttonNavLast : null}
            </Toolbar>
        }
        <div key={'navigation_clear'} className='float-clear' />
    </footer>;
};
AauComponentTablesNavigation.defaultProps = defaultProps;


