/*
 * Generator Component Library is a library design to be use with Ask And Use Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Cookies from "js-cookie";

export type aauComponentCodeLanguage = 'bash' | 'json' | 'accesslog' | 'gradle' | 'java' | 'kotlin' | 'python' | 'typescript' | 'shell' | 'yaml';

export interface AauComponentCodeProps {
    value?: string;
    showLineNumbers?: boolean
    language: aauComponentCodeLanguage
    wrapLines?: boolean
}

const defaultProps = {
    value: 'N.C.',
    showLineNumbers: true,
    language: 'bash',
    wrapLines: false
} as AauComponentCodeProps;

export const AauComponentCode: FC<AauComponentCodeProps> = props => {
    const isLogged = typeof (Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
    let profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

    return (
        <SyntaxHighlighter
            showLineNumbers={props.showLineNumbers}
            language={props.language}
            style={(profil.hasOwnProperty('darkMode') ? profil['darkMode'] : true) === true ? oneDark : oneLight}
            wrapLines={props.wrapLines}
        >
            {props.value}
        </SyntaxHighlighter>
    );
};
AauComponentCode.defaultProps = defaultProps;
